import React, { Component } from 'react';

import Page from '../containers/page';
import { API_ENDPOINT } from "../constants";

import { Player, Controls } from '@lottiefiles/react-lottie-player';
import AOS from 'aos';
import 'aos/dist/aos.css'
import CuroflowIcon from "../assets/images/Curoflow_Digital_Telemedicine_Platform_Icon.png"



import SupportForm from '../components/forms/supportForm7';
import ContactForm from '../components/forms/contactForm3';

function parseJSON(response) {
    return response.json().then((json) => {
        return {
            result: json,
            status: response.status,
        };
    });
}

var striptags = require('striptags');

class ContactPage extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();

        this.state = {
            promotedProjects: [],
            poolActive: false,
            chatForm: false,
            supportForm: false,
            contactUs: [],
            contactStatus: false,
            supportStatus: false,
            ...props.initialData
        };
    }

    sendMessage1 = (data) => {
        if (data.checked) {
            data.lang = this.props.lang
            this.setState({
                loading1: true
            }, () => {

                fetch(API_ENDPOINT + '/data/contact/new', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ data })
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                    }
                })
            })
        }
    }

    sendMessage2 = (data) => {
        console.log(data)
        this.props.setRecaptchaCallback(() => {

            if (data && data.checked && this.props.allowSubmit) {
                if (data.email && (data.email.toLowerCase().indexOf('@gmail.com') !== -1 || data.email.toLowerCase().indexOf('@live.com') !== -1 || data.email.toLowerCase().indexOf('@mailinator.com') !== -1 || data.email.toLowerCase().indexOf('@jollyfree.com') !== -1 || data.email.toLowerCase().indexOf('@gotgel.org') !== -1 || data.email.toLowerCase().indexOf('@yahoo.com') !== -1 || data.email.toLowerCase().indexOf('@hotmail.com') !== -1 || data.email.toLowerCase().indexOf('@business.com') !== -1 || data.email.toLowerCase().indexOf('@admin.com') !== -1 || data.email.toLowerCase().indexOf('@info.com') !== -1)) {
                    return this.setState({ contactFormError: 'Please fill in your work email' })
                } else this.setState({ contactFormError: null })

                // if (data.message?.length < 25) return this.setState({ contactFormMessageError: 'Please use at least 25 letters' });
                // else this.setState({ contactFormMessageError: null })

                data.lang = this.props.lang
                data.url = typeof window !== 'undefined' ? window.location?.pathname : 'No Data';
                data.bookADemo = false
                data.page = this.props.lang === "en" ? "Contact us" : "Kontakta oss"
                data.token = this.props.recaptchaToken
                data.isMobile = this.props.isMobile
                data.osMobileNameUA = this.props.osMobileNameUA
                data.browserName = this.props.browserName,
                    data.browserNameUA = this.props.browserNameUA
                this.setState({
                    loading2: true
                }, () => {

                    fetch(API_ENDPOINT + '/data/contact/new', {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({ data })
                    }).then(parseJSON).then(({ result, status }) => {
                        if (status === 429) {
                            this.setState({
                                contactStatus: true,
                                loading2: false
                            })
                        }
                        if (result.error) {
                            this.setState({ error: result.error })
                        } else {
                            const path = 'thank-you';
                            this.props[0].history.push(`/${path}`);
                        }
                    })
                })
            }
        })
        this.props.updateToken();

    }

    sendMessage40 = (data) => {
        this.props.setRecaptchaCallback(() => {

            if (data && data.checked && this.props.allowSubmit) {
                if (data.email && (data.email.toLowerCase().indexOf('@gmail.com') !== -1 || data.email.toLowerCase().indexOf('@live.com') !== -1 || data.email.toLowerCase().indexOf('@mailinator.com') !== -1 || data.email.toLowerCase().indexOf('@jollyfree.com') !== -1 || data.email.toLowerCase().indexOf('@gotgel.org') !== -1 || data.email.toLowerCase().indexOf('@yahoo.com') !== -1 || data.email.toLowerCase().indexOf('@hotmail.com') !== -1 || data.email.toLowerCase().indexOf('@business.com') !== -1 || data.email.toLowerCase().indexOf('@admin.com') !== -1 || data.email.toLowerCase().indexOf('@info.com') !== -1)) {
                    return this.setState({ contactFormError10: 'Please fill in your work email' })
                } else this.setState({ contactFormError10: null })
                // if (data.message?.length < 25) return this.setState({ contactFormMessageError10: 'Please use at least 25 letters' });
                // else this.setState({ contactFormMessageError10: null })
                data.lang = this.props.lang
                data.token = this.props.recaptchaToken

                this.setState({
                    loading3: true
                }, () => {

                    fetch(window.location.origin == 'https://qa.curoflow.se' ? 'https://testapi.curoflow.se/support-category/tiket-site' : 'https://dashboardapi.curoflow.se/support-category/tiket-site', {
                        // fetch('http://localhost:4005/support-category/tiket-site', {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    }).then(parseJSON).then(({ result, status }) => {
                        if (status === 429) {
                            this.setState({
                                supportStatus: true,
                                loading3: false
                            })
                        }
                        if (result.error) {
                            this.setState({ error: result.error })
                        } else {
                        }
                    })

                })
            }
        })
        this.props.updateToken();

    }

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        AOS.init({
            // initialise with other settings
            duration: 1000
        });

        this.get();
    }

    render() {
        return (
            <div>
                {
                    this.state.supportForm ?
                        <div className='overlay overlay-active' onClick={() => {
                            this.setState({
                                supportForm: false
                            })
                        }}></div>
                        :
                        null
                }

                <div className={this.state.supportForm ? 'contact-popup open' : 'contact-popup'}>
                    <span className="close-icon" onClick={() => {
                        this.setState({
                            supportForm: false
                        })
                    }}>x</span>
                    {
                        !this.state.loading3 && !this.state.supportStatus ?
                            <SupportForm onSubmit={this.sendMessage40} lang={this.props.lang} showTitle={true} contactFormError={this.state.contactFormError10} contactFormMessageError={this.state.contactFormMessageError10} />
                            : null
                    }
                    {
                        this.state.loading3 ?
                            <Player
                                ref={this.player}
                                autoplay={true}
                                onEvent={(event) => {
                                    if (event == 'complete') {
                                        this.setState({
                                            supportForm: false,
                                            loading3: false
                                        })
                                    }
                                }}
                                //keepLastFrame={true}
                                //loop={false}
                                controls={true}
                                src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
                                style={{ height: '300px', width: '300px' }}
                            ></Player>
                            :
                            null
                    }
                    {this.state.supportStatus && <h2 className='status-popup support-status'>{'Please, try again later!'.translate(this.props.lang)}</h2>}
                </div>


                <section className="sectionTextBigimg sectionTextBigImgNoBg contactPage">

                    <div className="title">
                        <h1>{this.state.contactUs && this.state.contactUs[0] && this.state.contactUs[0]?.title[this.props.lang]} </h1>
                        <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.contactUs && this.state.contactUs[0], 'shortDescription', this.props.lang) }}></div>
                    </div>
                    <ul className='email-tel'>
                        <li><p>{'Email'.translate(this.props.lang)}: <a href={`mailto:${Object.translate(this.state.contactUs && this.state.contactUs[0], 'email', this.props.lang)}`}><span dangerouslySetInnerHTML={{ __html: Object.translate(this.state.contactUs && this.state.contactUs[0], 'email', this.props.lang) }}></span></a></p></li>
                        <li><p>{'Telephone'.translate(this.props.lang)}: <span dangerouslySetInnerHTML={{ __html: Object.translate(this.state.contactUs && this.state.contactUs[0], 'phone', this.props.lang) }}></span></p></li>
                    </ul>
                    <img src={CuroflowIcon} className="rightSide" alt="Curoflow Digital Telemedicine Platform Icon" />
                    <div className='support-contact' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.contactUs && this.state.contactUs[0], 'text1', this.props.lang) }}></div>
                    <button onClick={() => { this.setState({ supportForm: true }) }} className='support-button'>{"Contact our support".translate(this.props.lang)}</button>
                </section>
                <ContactForm text1={this.state.contactUs && this.state.contactUs[0] && this.state.contactUs[0] ? Object.translate(this.state.contactUs && this.state.contactUs[0] && this.state.contactUs[0], 'text2', this.props.lang) : null} class={'grey'} contactFormError={this.state.contactFormError} lang={this.props.lang} isMobile={this.props.isMobile} justShowForm={true} onSubmit={this.sendMessage2} showTextContact={true} contactPage={true} contactFormMessageError={this.state.contactFormMessageError} />


            </div>
        );
    }
}

export default Page(ContactPage);

