import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import Link from '../link';

import Check from './fields/check';
import Text from './fields/text';
import Select from './fields/select';
import Textarea from './fields/textarea';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    Label
} from 'reactstrap';

const required = value => value ? undefined : "Required"



const renderCheckField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },

}) => (

    <Check
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        // multilang={multilang}
        // lang={lang}

        {...input}
    />
)

const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    children,
    additionalAction,
    scope,
}) => (

    <Select
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        additionalAction={additionalAction}
        scope={scope}
        {...input}
        children={children}
    />
)

const renderTextField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type
}) => (

    <Text
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        {...input}
    />
)

const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

    <Textarea
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)


class form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        const { handleSubmit, pristine, reset, submitting, menu } = this.props;
        // console.log(pristine, submitting);

        return (
            <form onSubmit={handleSubmit} className="chat-form">
                <Row>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="email"
                                type="email"
                                component={renderTextField}
                                label={"E-mail".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="message"
                                component={renderTextareaField}
                                label={"Meddelande".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                             <Field
                                name="checked"
                                component={renderCheckField}
                                placeholder=""
                                label={<Label>{'Genom att skicka in din E-mailadress godkänner du Curoflows'.translate(this.props.lang)}  
                                <span> <Link lang={this.props.lang} to="/integritetspolicy" target="_blank">{'integritetspolicy'.translate(this.props.lang)}</Link> </span></Label>}
                                 validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12" className="btn-wrap">
                        <button type="submit" className="chat-btn">{'Skicka'.translate(this.props.lang)}</button>
                    </Col>
                </Row>
            </form>

        )
    }
}

export default reduxForm({
    form: 'form8'  // a unique identifier for this form
})(form)
