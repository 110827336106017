import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import Link from '../link';


import Check from './fields/check';
import Text from './fields/text';
import Select from './fields/select';
import Textarea from './fields/textarea';
import Phone from './fields/phone';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    Label
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderCheckField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },

}) => (

    <Check
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        // multilang={multilang}
        // lang={lang}

        {...input}
    />
)

const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    children,
    additionalAction,
    scope,
}) => (

    <Select
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        additionalAction={additionalAction}
        scope={scope}
        {...input}
        children={children}
    />
)

const renderTextField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type
}) => (

    <Text
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        {...input}
    />
)

const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    name
}) => (

    <Textarea
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        name={name}
        {...input}
       
    />
)


const renderPhoneField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

    <Phone
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)



class form extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
        }
    }

   

    render() {
        const { handleSubmit, pristine, reset, submitting, menu, invalid, contactFormMessageErr, contactFormMessageError } = this.props;
        // console.log(this.props)
        const onSubmit = e => {
            e?.preventDefault();
            if (invalid) {
                this.setState({ error: 'Fill in the required information and try again' });
            } else {
                this.setState({ error: null })
            }
            handleSubmit();
        }
        return (
            <div className={`curoflow-${this.props.class}-form curoflow-form`}>
                <div className={'container-curoflow'}>
                        <div className='col-curoflow-lg-6 align-div'>
                            {this.props.title?
                                <h2>{this.props.title}</h2> : null
                            }
                    
                            {this.props.text?
                                <div dangerouslySetInnerHTML={{__html: this.props.text}} /> : null
                            }
                            {
                                this.props.contactPage ? 
                                <div>
                                    {/* <button onClick={()=>{
                                        this.props.openForm()
                                    }} className='support-button'>{"Contact our support".translate(this.props.lang)}</button> */}
                                    {this.props.text1?
                                <div dangerouslySetInnerHTML={{__html: this.props.text1}} /> : null
                            }
                                </div>
                                    :
                                    null
                            }
                        </div>

                    {/* { this.props.showTextContact? <div>
                        {this.props.title?
                            <h2>{this.props.title}</h2> : null
                        }
                
                        {this.props.text?
                            <div dangerouslySetInnerHTML={{__html: this.props.text}} /> : null
                        }
                        
                    </div> : null

                   
          

                     } */}

                    <div className='col-curoflow-lg-6'>
                        <form onSubmit={onSubmit} className="contact-form new-contact-form">
                            <Row>
                                <Col lg="6">
                                    <div className="field-wrap">
                                        <Field
                                            name="name"
                                            component={renderTextField}
                                            // label={"Namn".translate(this.props.lang)}
                                            validate={[required]}
                                            placeholder={"Name".translate(this.props.lang)}
                                        ></Field>
                                    </div>
                                </Col>
                                
                                    <Col lg="6">
                                        <div className="field-wrap">
                                            <Field
                                                name="country"
                                                component={renderTextField}
                                                // label={"Namn".translate(this.props.lang)}
                                                validate={[required]}
                                                placeholder={"Land".translate(this.props.lang)}
                                            ></Field>
                                        </div>
                                    </Col>
                                    
                                    
                                <Col lg="6">
                                    <div className="field-wrap">
                                        <Field
                                            name="title"
                                            component={renderTextField}
                                            // label={"Titel".translate(this.props.lang)}
                                            placeholder={'Job title'.translate(this.props.lang)}
                                            validate={[required]}
                                        ></Field>
                                    </div>
                                </Col>
                             
                                <Col lg="6">
                                    <div className="field-wrap">
                                        <Field
                                            name="organization"
                                            component={renderTextField}
                                            // label={"Organisation".translate(this.props.lang)}
                                            placeholder={'Organisation'.translate(this.props.lang)}
                                            validate={[required]}
                                        ></Field>
                                    </div>
                                </Col>
                            
                                <Col lg="6">
                                    <div className={`field-wrap ${this.props.contactFormError ? 'invalidEmail' : ''}`}>
                                        <Field
                                            name="email"
                                            type="email"
                                            component={renderTextField}
                                            // label={"E-mail".translate(this.props.lang)}
                                            placeholder={'Work email'.translate(this.props.lang)}
                                            validate={[required]}
                                        ></Field>
                                    </div>
                                </Col>
                                {/* <Col lg="6">
                                <div className="field-wrap">
                                    <Field
                                        name="phone"
                                        component={renderTextField}
                                        label={"Telefonnummer".translate(this.props.lang)}
                                    ></Field>
                                </div>
                            </Col> */}
                            
                                <Col lg="6">
                                    <div className="field-wrap custom-phone-number">
                                    <Field
                                            name="phone"
                                            component={renderPhoneField}
                                            placeholder={'Telefonnumer'.translate(this.props.lang)}
                                            validate={[required]}
                                        ></Field>
                                    </div>
                                </Col>
                                
                                <Col lg="12">
                                <div className={`field-wrap ${this.props.contactFormMessageError ? 'invalidTextarea' : ''}`}>
                                        <Field
                                            name="message"
                                            component={renderTextareaField}
                                            // label={"Meddelande".translate(this.props.lang)}
                                            placeholder={'Meddelande'.translate(this.props.lang)}
                                            validate={[required]}
                                        ></Field>
                                    </div>
                                </Col>
                                <Col lg="12">
                                    <div className="field-wrap label-set button-form">
                                        <Field
                                            name="checked"
                                            component={renderCheckField}
                                            placeholder=""
                                            label={<Label>{'Jag godkänner Curoflows'.translate(this.props.lang)}
                                                <span> <Link lang={this.props.lang} to="/integritetspolicy" target="_blank">{'integritetspolicy'.translate(this.props.lang)}</Link> </span></Label>}
                                            validate={[required]}
                                        ></Field>
                                        <button type="submit" className="button-clasic-black">{'Skicka'.translate(this.props.lang)}</button>
                                    </div>
                                </Col>
                                <Col lg="12" className="btn-wrap">

                                   
                                {this.state.error || this.props.contactFormError || this.props.contactFormMessageError ? <p className='error-contact-form'>{this.state.error ? this.state.error.translate(this.props.lang) : this.props.contactFormError?.translate(this.props.lang) ? this.props.contactFormError.translate(this.props.lang) : this.props.contactFormMessageError ? this.props.contactFormMessageError?.translate(this.props.lang) :  null}</p> : null}
                                </Col>

                            </Row>
                        </form>
                    </div>

            </div>
            </div>
      


        )
    }
}

export default reduxForm({
    form: 'contactForm3',  // a unique identifier for this form
})(form)
