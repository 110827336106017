import React, { Component } from 'react';

class ToggleSwitch extends Component {


    render() {
        return (
            <div className='flex-cookies'>
                <p>{this.props.label}</p>
                <div className="toggle-switch">
                    <input
                        type="checkbox"
                        className="toggle-switch-checkbox"
                        name={this.props.Name}
                        id={this.props.Name}
                        checked={this.props.checked}
                        onChange={this.props.onChange}
                    />
                    <label className="toggle-switch-label" htmlFor={this.props.Name}>
                        <span className="toggle-switch-inner" />
                        <span className={this.props.class ? "toggle-switch-switch not-changed" : "toggle-switch-switch"} />
                    </label>
                </div>
            </div>
        );
    }
}

export default ToggleSwitch;
