import React, { Component } from "react";
import Page from "../containers/page";
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import { API_ENDPOINT } from '../constants';


function parseJSON(response) {
    return response.json().then((json) => {
        return {
            result: json,
            status: response.status,
        };
    });
}

function parseText(response) {
    return response.text().then(text => {
        return {
            result: text,
            status: response.status
        }
    })
}

class Unsubscirbe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPlayer: false,
            showPlayer2: false,
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                "query-group": null,
            },
            items: [],
            total: 0,
            loading: true,
            groups: [],
            languages: [],
            clinicIdx: 0,
            showForm: false,
            checkedMails: []
        };
    }

    componentDidMount() {

        fetch(API_ENDPOINT + '/data/unsubscribe', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                
            },
            body: JSON.stringify({
                email: this.props[0].match.params.email
            })
        }).then(parseJSON).then(({ result, status }) => {
           this.props[0].history.push('/')
        })

    }

    render() {

        return (
            <div className="dashboard">

            </div>
        );
    }
}

const selector = formValueSelector('form');

export default connect(state => {
    return {
        userLevel: selector(state, 'description'),
        userLevel: selector(state, 'subject'),
        userLevel: selector(state, 'name')

    }


}, {
    changeData: value => change("form", "description", value),
    changeDataSubject: value => change("form", "subject", value),
    changeDataName: value => change("form", "name", value)
})(Page(Unsubscirbe));
