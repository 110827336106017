import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import gp from '../assets/images/googlePlay.png'
import as from '../assets/images/appStore.png'
import logoIcon from '../assets/images/logo.png';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Input,
    Button
} from 'reactstrap';

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

class NewsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }
    // get = () => {
    //     this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

    //     for (let i = 0; i < this.props.loadData.length; i++) {
    //         this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
    //             this.setState({
    //                 ...data,
    //                 loading: null
    //             }, () => {
    //                 this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
    //             })
    //         })
    //     }

    //     for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
    //         this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
    //             this.setState({
    //                 ...data,
    //                 loading: null
    //             }, () => {
    //                 this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
    //             })
    //         })
    //     }

    // }
    getPlatform = () => {
        var platform = ["Win32", "Android", "iOS"];

        for (var i = 0; i < platform.length; i++) {

            if (navigator.platform.indexOf(platform[i]) > - 1) {

                return platform[i];
            }
        }
    }
    componentDidMount() {
        // if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        // this.get();


        // setTimeout(() => {
        let platform = getMobileOperatingSystem();
        if (typeof window != 'undefined') {
            if (platform == 'Android') {
                window.open('https://play.google.com/store/apps/details?id=com.curoflow.app', '_self')
            } else {
                window.open('https://apps.apple.com/us/app/curo-kliniken/id1626972788', '_self')
            }
        }
        // }, 1000)


    }


    render() {
        return (
            <div className='download'>

                <div className='download-content'>
                    <img src={logoIcon} />
                    <div className='download-content-buttons'>
                        <a href={'https://apps.apple.com/us/app/curo-kliniken/id1626972788'}>
                            <img src={gp} />
                        </a>
                        <a href='https://play.google.com/store/apps/details?id=com.curoflow.app'>
                            <img src={as} />
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default Page(NewsPage);

