import React, { Component } from 'react';
import Page from '../containers/page';

class FormSubmitted extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // document.title = "Form Submitted";

        const div = document?.getElementById('social-intents-tab-chat');
        if (div) {
            div.style.display = 'none';
        }
    }

    render() {


        return (
            <>
                 
            </>
        );
    }
}

export default Page(FormSubmitted);

