import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,

} from 'react-router-dom';
import {GoogleMapScript} from './components/googleMapScript';

import { routes, generateRoutes } from './routesList';



class Routes extends Component {


    render() {
        return (
            <div>
                                {/* <GoogleMapScript API_KEY="AIzaSyDx7uNRz2GYWKLlAlfT6wugFOSBXQ7EZaQ" /> */}

                <Switch className="react-switch">
                    {
                        generateRoutes(routes, this.props.lang).map((route) => {
                            return (
                                <Route
                                    path={route.path}
                                    exact
                                    render={(...renderProps) => {
                                        const Component = route.component;
                                        let generateSeoTags = route.generateSeoTags;
                                        if (typeof window == 'undefined'){
                                            generateSeoTags = (data, lang) => {
                                                return route.generateSeoTags(data, route.ssrLang)
                                            }
                                        }
                                        return (
                                            <Component {...renderProps} {...this.props} loadData={route.loadData} loadDataWithQuery={route.loadDataWithQuery} generateSeoTags={generateSeoTags}/>
                                        )
                                    }}
                                />

                            )
                        })
                    }
                </Switch>
            </div>
        );
    }
}

export default Routes;