import React, { Component } from 'react';
// import { Link, Redirect } from 'react-router-dom';
import Link from "../components/link";
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import { API_ENDPOINT } from "../constants";
import moment from 'moment';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Input
} from 'reactstrap';

import article from '../assets/images/article.png';
import Img from './Img';


var striptags = require('striptags');

class Article extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotedProjects: [],
            article: {},
            poolActive: false,
            ...props.initialData
        };
    }

    componentDidMount() {
        if (this.props.article) {
            console.log('stigla trenutna stranica', this.props.pageNumber);
            
            this.setState({
                article: {
                    article: {
                        ...this.props.article,
                        pageNumber: this.props.pageNumber ? this.props.pageNumber : 0
                    }
                }
            })
        }
    }
    render() {
        return (

            <Link to={this.props.articlesPage ? `/artiklar-om-mjukvara-for-digital-vard/${this.props.address ? this.props.address[this.props.lang] : "/"}` : `${this.props.address ? this.props.address[this.props.lang] : "/"}`} state={this.state.article} lang={this.props.lang} className="article-link">
                <article className="article">

                    <h4>
                        {
                            this.props.title /*&& this.props.title.length > 36 ?
                                    this.props.title.substr(0, 33) + '...'
                                    :*/ &&
                            this.props.title
                        }
                    </h4>

                    <div dangerouslySetInnerHTML={{ __html: this.props.shortDescriptionPage ? this.props.shortDescriptionPage : '' }}></div>
                    <span className="time">{this.props.date ? this.props.date : moment.unix(this.props.tsCreated).format(`YYYY-MM-DD`)}</span>
                    <Img src={API_ENDPOINT + this.props.backgroundImage} alt={this.props.backgroundImage && this.props.backgroundImage.replace('/uploads/', '')?.replace(/_/g, ' ')?.slice(0, -4)} />
                    {/* <p><div dangerouslySetInnerHTML={{ __html: this.props.description && this.props.description.length > 110 ? this.props.description.substr(0, 170) : this.props.description}}></div></p> */}
                    <Link to={this.props.articlesPage ? `/artiklar-om-mjukvara-for-digital-vard/${this.props.address ? this.props.address[this.props.lang] : "/"}` : `${this.props.address ? this.props.address[this.props.lang] : "/"}`} state={this.state.article} className="button-clasic" lang={this.props.lang}>{this.props.buttonText}</Link>
                </article>
            </Link>

        );
    }
}

export default Page(Article);

