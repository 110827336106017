import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import { API_ENDPOINT } from "../constants";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import CuroflowIcon from "../assets/images/Curoflow_Digital_Telemedicine_Platform_Icon.png"
import SupportForm from '../components/forms/supportForm';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Input
} from 'reactstrap';
import arrowDown from '../assets/svg/arrow-down.svg';
import chat from '../assets/images/chat.png';
import ChatForm from '../components/forms/chatForm2';
import ContactForm from '../components/forms/contactForm';
import g1 from '../assets/images/g1.jpeg';
import g2 from '../assets/images/g2.png';
import g3 from '../assets/images/g3.png';
import AOS from 'aos';
import 'aos/dist/aos.css'

var striptags = require('striptags');

function parseJSON(response) {
    return response.json().then((json) => {
      return {
        result: json,
        status: response.status,
      };
    });
  }

class GdprPage extends Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.player = React.createRef(); 

        this.state = {
            promotedProjects: [],
            poolActive: false,
            chatForm: false,
            contactForm: false,
            yScroll2: 0,
            gdprPage:[],
            ...props.initialData
        };
    }

    listenToScroll = () => {
        const yScroll2 =
            document.body.scrollTop || document.documentElement.scrollTop


        this.setState({
            yScroll2: yScroll2,
        })
    }

    sendMessage1 = (data) => {
        if(data.checked) {
            data.lang = this.props.lang
            this.setState({
                loading1: true
            }, () => {
       
                fetch(API_ENDPOINT + '/data/contact/new', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({data})   
                }).then(parseJSON).then(({result, status}) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                    }
                })
            })
        }
    }

    sendMessage2 = (data) => {
        if(data.checked) {
            data.lang = this.props.lang
            this.setState({
                loading2: true
            }, () => {
                
                fetch(API_ENDPOINT + '/data/contact/new', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({data})   
                }).then(parseJSON).then(({result, status}) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                        const path = 'thank-you'.translate(this.props.lang);
                        this.props[0].history.push(`/${path}`);
                    }
                })
            })
        }
    }

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }
    
    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.updateWindowDimensions();
        window.addEventListener('scroll', this.listenToScroll)

        AOS.init({
            duration: 1000
        })

      this.get();
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });

        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

    }
    

    render() {
      

        return (
            <div>

            <div className={this.state.contactForm ? 'contact-popup open' : 'contact-popup'}>
                        <span className="close-icon" onClick={() => {
                            this.setState({
                                contactForm: false
                            })
                        }}>x</span>
                {
                    !this.state.loading2 ? 
                    <ContactForm onSubmit={this.sendMessage2} lang={this.props.lang} />
                    :null
                }
                {
                     this.state.loading2 ? 
                    <Player
                        ref={this.player} 
                        autoplay={true}
                        onEvent={(event) => {
                            if(event == 'complete') {
                                this.setState({
                                    contactForm: false,
                                    loading2: false
                                })
                            }
                        }}
                        //keepLastFrame={true}
                        //loop={false}
                        controls={true}
                        src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
                        style={{ height: '300px', width: '300px' }}
                    ></Player>
                    :
                    null
                }
                </div>
                <div className={this.props.openModalBookDemo  ? 'contact-popup open' : 'contact-popup'}>
                    <span className="close-icon" onClick={() => {
                         this.props.bookDemo(false)
                    }}>x</span>
                    {
                        !this.state.loading3 && !this.state.supportStatus?
                            <SupportForm onSubmit={this.sendMessage2} lang={this.props.lang} />
                            : null
                    }
                    {
                        this.state.loading3 ?
                            <Player
                                ref={this.player}
                                autoplay={true}
                                onEvent={(event) => {
                                    if (event == 'complete') {
                                        this.setState({
                                            supportForm: false,
                                            loading3: false
                                        })
                                    }
                                }}
                                //keepLastFrame={true}
                                //loop={false}
                                controls={true}
                                src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
                                style={{ height: '300px', width: '300px' }}
                            ></Player>
                            :
                            null
                    }
                    {this.state.supportStatus && <h2 className='status-popup support-status'>{'Please, try again later!'.translate(this.props.lang)}</h2>}
                </div>

                {/* <div className="chat-icon">
                    <img src={chat} alt="chat" onClick={() => {
                        this.setState({
                            chatForm: !this.state.chatForm
                        })
                    }} />

                    <div className={this.state.chatForm ? 'chat-form-container opened' : 'chat-form-container'}>
                    <span className="close-icon" onClick={() => {
                            this.setState({
                                chatForm: false
                            })
                        }}>x</span>
                        <h2>{'Kontakta oss'.translate(this.props.lang)}</h2>
                                    {
                                        !this.state.loading1 ? 
                                        <ChatForm onSubmit={this.sendMessage1} lang={this.props.lang} />
                                        :null
                                    }
                                    {
                                        this.state.loading1 ? 
                                        <Player
                                            ref={this.player} 
                                            autoplay={true}
                                            keepLastFrame={true}
                                            //loop={true}
                                            controls={true}
                                            src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
                                            style={{ height: '100px', width: '100px' }}
                                        ></Player>
                                        :
                                        null
                                    }
                        <div className="chat-info">
                            <h6>
                                {'Du kan aven kontakta oss via telefon:'.translate(this.props.lang)}
                                <a href="tel:0107500655">{this.props.lang == 'en' ? '+46 (0)10-750 06 55' : '010-750 06 55'}</a>
                            </h6>
                        </div>
                    </div>
                </div> */}

                {/* <Isvg src={arrowDown} alt="cta" className={this.state.yScroll2 > 355 ? 'cta-arrow-2 cta-arrow-2-show': 'cta-arrow-2'} onClick={() => {
                    window.scrollTo(0, 0);
                }} /> */}

                    <section className='sectionTextBigimg sectionTextBigImgNoBg'>
                        <div className="title">
                        <h1>{this.state.gdprPage && this.state.gdprPage[0]  && this.state.gdprPage[0]?.title[this.props.lang]} </h1>
                            <div dangerouslySetInnerHTML={{__html: Object.translate(this.state.gdprPage && this.state.gdprPage[0], 'shortDescription', this.props.lang)}}></div>
                        </div>
                    </section>

                    <section className='curoflow-section curoflow-paddiing curoflow-bg-grey gdpr-section2'>
                       
                        <h1 className='text-center'>{this.state.gdprPage && this.state.gdprPage[1]  && this.state.gdprPage[1].item[0]?.title[this.props.lang]} </h1>
                        <h2 className='text-center'>{this.state.gdprPage && this.state.gdprPage[1]  && this.state.gdprPage[1].item[0]?.subTitle[this.props.lang]} </h2>
                        <div className="container-curoflow ">
                            <div className="col-curoflow-lg-6 ">
                            
                                <div dangerouslySetInnerHTML={{__html: Object.translate(this.state.gdprPage && this.state.gdprPage[1]  && this.state.gdprPage[1].item[1], 'shortDescription', this.props.lang)}}></div>
                            </div>
                            <div className="col-curoflow-lg-6 ">
                            
                            <div dangerouslySetInnerHTML={{__html: Object.translate(this.state.gdprPage && this.state.gdprPage[1]  && this.state.gdprPage[1].item[2], 'shortDescription', this.props.lang)}}></div>
                        </div>
                        </div>
                        <img src={CuroflowIcon} className="leftSideGdpr" alt="Curoflow Digital Telemedicine Platform Icon"/>
                    </section>
            
                    <section className='curoflow-section  gdpr-section3'>
                       
                        <h1 className='text-center'>{this.state.gdprPage && this.state.gdprPage[2]  && this.state.gdprPage[2].item[0]?.title[this.props.lang]} </h1>
                        <h2 className='text-center'>{this.state.gdprPage && this.state.gdprPage[2]  && this.state.gdprPage[2].item[0]?.subTitle[this.props.lang]} </h2>
                        <div className="container-curoflow ">
                            <div className="col-curoflow-lg-6 ">
                            
                                <div className="content-gdpr-left" dangerouslySetInnerHTML={{__html: Object.translate(this.state.gdprPage && this.state.gdprPage[2]  && this.state.gdprPage[2].item[1], 'shortDescription', this.props.lang)}}></div>
                                <div dangerouslySetInnerHTML={{__html: Object.translate(this.state.gdprPage && this.state.gdprPage[2]  && this.state.gdprPage[2].item[1], 'shortDescription1', this.props.lang)}}></div>
                            </div>
                            <div className="col-curoflow-lg-6 ">
                            <div className="content-gdpr-right" dangerouslySetInnerHTML={{__html: Object.translate(this.state.gdprPage && this.state.gdprPage[2]  && this.state.gdprPage[2].item[3], 'shortDescription', this.props.lang)}}></div>
                                <div className="content-gdpr-right" dangerouslySetInnerHTML={{__html: Object.translate(this.state.gdprPage && this.state.gdprPage[2]  && this.state.gdprPage[2].item[3], 'shortDescription1', this.props.lang)}}></div>
                                <div dangerouslySetInnerHTML={{__html: Object.translate(this.state.gdprPage && this.state.gdprPage[2]  && this.state.gdprPage[2].item[3], 'shortDescription2', this.props.lang)}}></div>
                            
                        </div>
                        </div>
                    </section>

  


                    <div className={this.state.contactForm ? 'overlay overlay-active' : 'overlay'} onClick={() => {
                        this.setState({
                            contactForm: false
                        })
                    }}></div>

            </div>
        );
    }
}

export default Page(GdprPage);

