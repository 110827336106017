import React, { Component } from "react";
// import { Link } from "react-router-dom";
import Link from "../components/link";
import { API_ENDPOINT } from "../constants";

function parseJSON(response) {
  return response.json().then((json) => {
    return {
      result: json,
      status: response.status,
    };
  });
}

class CustomDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      children: [],
      resourcesLinks: [
        {
          link: "/kunders-feedback-pa-Curoflow-digital-vardplattform",
          name: "Customers tell us",
          position: 2,
          special: true,
        },
        {
          link: "/gdpr-efterlevnad-i-Curoflow-digital-vardplattform",
          name: "GDPR compliance".translate(this.props.lang),
          position: 3,
          special: true,
          lang: this.props.lang
        },
        {
          link: "/nyheter-om-curoflow-vardplattform",
          name: "News",
          position: 4,
          special: true,
          lang: this.props.lang
        },
        {
          link: "/artiklar-om-mjukvara-for-digital-vard",
          name: "Articles",
          position: 4,
          special: true,
          lang: this.props.lang
        },
        {
          link: "https://shows.acast.com/curopodden",
          name: "Podcast",
          position: 5,
          special: true,
        },
  
      ],
      otherAboutUsLinks: [
        // {
        //   link: "/",
        //   name: "About us",
        //   position: 1,
        //   special: true,
        //   lang: this.props.lang
        // },
        {
          link: "/kontakta-oss",
          name: "Contact us",
          position: 1,
          special: true,
          lang: this.props.lang
        },
        {
          link: "https://jobs.curoflow.se/",
          name: "Career",
          position: 4,
          special: true,
        },
        {
          link: "/cookies",
          name: "Cookies",
          position: 6,
          special: true,
        },
        // {
        //   link: "/cookies",
        //   name: "Cookies",
        //   position: 6,
        //   special: true,
        // },
        {
          link: "/integritetspolicy",
          name: "Privacy policy",
          position: 7,
          special: true,
        },
        // {
        //   link: "/solution",
        //   name: "Articles",
        //   position: 4,
        //   special: true,
        //   lang: this.props.lang
        // },
        // {
        //   link: "https://shows.acast.com/curopodden",
        //   name: "Podcast",
        //   position: 5,
        //   special: true,
        // },
      ]
    };
  }

  componentDidMount() {
    if (this.state.children && !this.state.children.length && this.props.apiName !== 'resources') {
      fetch(API_ENDPOINT + "/data/pages/" + this.props.apiName, {
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      })
        .then(parseJSON)
        .then(({ result, status }) => {
          if (result.error) {
            this.setState({ error: result.error });
          } else {
            this.setState(
              {
                children: [...result],
              },
              () => {
                let children = [...this.state.children]
                for (let i = 0; i < this.state.children.length; i++) {
                  // if (children[i].name["en"] === "Go Flow") {
                  //   children[i].position = 1;
                  // }
                  // if (children[i].name["en"] === "Partners") {
                  //   children[i].position = 5;
                  // }

                  if (this.state.children[i].section && this.state.children[i].section.length) {
                    for (let m = 0; m < this.state.children[i].section.length; m++) {
                      if (
                        this.state.children[i].section[m] &&
                        this.state.children[i].section[m].item &&
                        this.state.children[i].section[m].item[0] &&
                        this.state.children[i].section[m].item[0].isTitleLink &&
                        this.state.children[i].section[m].item[0].title &&
                        this.state.children[i].section[m].item[0].titleLinkText
                      ) {
                        children.splice(i + 1, 0, {
                          name: this.state.children[i].section[m].item[0].title,
                          title:
                            this.state.children[i].section[m].item[0]
                              .titleLinkText,
                          alias: this.state.children[i].alias,
                          sectionID: "section" + m,
                          additionalLink: true,
                        });
                      }
                    }
                  }
                }
                this.setState({
                  children
                })

                if (this.props.apiName === "all-aboutus") {
                  let children = this.state.children;
                  for (
                    let i = 0;
                    i < this.state.otherAboutUsLinks.length;
                    i++
                  ) {
                    children.push({
                      ...this.state.otherAboutUsLinks[i],
                    });
                  }
                  children.sort((a, b) => {
                    return a.position - b.position;
                  });
                  this.setState({
                    children,
                  });
                }
              }
            );
          }
        });
    }

    if(this.props.apiName === 'resources'){
      this.setState({
        children: [...this.state.resourcesLinks]
      })
    }
  }

  render() {
    return (
      <div className="content content-11">
        <h6>{this.props.label}</h6>

        <ul className="footer-list links-f">
          {this.state.children &&
            this.state.children.map((item) => {
              
              if (item && item.link && item.link.indexOf("https://") !== -1) {
                return (
                  <li>
                    <a
                      href={
                        item && item.link && item.link.indexOf("https://") !== -1
                          ? item.link
                          : ""
                      }
                      target= "_blank"
                    >
                      {item.name && typeof item.name === "object"
                        ? item.name[this.props.lang]
                        : item.name
                          ? item.name.translate(this.props.lang)
                          : ""}
                    </a>
                  </li>
                );
              } else if(item && item.alias && item.alias[this.props.lang]){
                return (
                  <li>
                    <Link lang={this.props.lang} to={
                      
                        this.props.pathName && !item.additionalLink ?
                            '/' +
                            this.props.pathName +
                            item.alias[this.props.lang]
                          :
                       this.props.pathName && item.additionalLink ?
                            '/' +
                            this.props.pathName +
                            item.alias[this.props.lang] +
                            `#${item.sectionID}`
                        :
                        ''

                        }
                    
                    >
                      {item.name && typeof item.name === "object"
                        ? item.name[this.props.lang]
                        : item.name
                          ? item.name.translate(this.props.lang)
                          : ""}
                    </Link>
                  </li>
                );
              }else if(item.special){
                return(
                  <li>
                    {/* <a href={
                      item.special ?
                      window.location.origin +
                          
                            item.link
                            :
                            ''
                    }>
                        {item.name
                          ? item.name.translate(this.props.lang)
                          : ""}
                    </a> */}
                    
                      <Link lang={this.props.lang} to={item.link} > {item.name ? item.name.translate(this.props.lang) : ""} </Link>                   
                  </li>
                )
              }

            })}
        </ul>
      </div>
    );
  }
}

export default CustomDropdown;
