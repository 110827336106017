import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import { API_ENDPOINT } from "../constants";
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Input
} from 'reactstrap';

import ContactForm from '../components/forms/contactForm';
import arrowDown from '../assets/svg/arrow-down.svg';
import p1 from '../assets/images/p1.png';
import p2 from '../assets/images/p2.png';
import p3 from '../assets/images/p3.png';
import p4 from '../assets/images/p4.png';
import p5 from '../assets/images/p5.png';
import p6 from '../assets/images/p6.png';
import p7 from '../assets/images/p7.png';
import chat from '../assets/images/chat.png';
import ChatForm from '../components/forms/chatForm9';

var striptags = require('striptags');

function parseJSON(response) {
    return response.json().then((json) => {
        return {
            result: json,
            status: response.status,
        };
    });
}

class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.player = React.createRef();

        this.state = {
            promotedProjects: [],
            poolActive: false,
            chatForm: false,
            yScroll2: 0,
            privacyPolicy: [],
            ...props.initialData
        };
    }

    listenToScroll = () => {
        const yScroll2 =
            document.body.scrollTop || document.documentElement.scrollTop


        this.setState({
            yScroll2: yScroll2,
        })
    }

    sendMessage1 = (data) => {
        if (data.checked) {
            data.lang = this.props.lang
            this.setState({
                loading1: true
            }, () => {
                3

                fetch(API_ENDPOINT + '/data/contact/new', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ data })
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                    }
                })
            })
        }
    }

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }

        this.updateWindowDimensions();
        window.addEventListener('scroll', this.listenToScroll)

        this.get();

        if (this.props[0].location && this.props[0].location.search.indexOf('lang=') != -1) {
            this.setState({
                lang: this.props[0].location.search.split('=')[1]
            })
        }

    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            // this.get()
        }
    }

    render() {

        return (
            <div>

            

                <div className="policy">
                    <Container>
                        <Row>
                            <Col lg="12" className="m-auto">

                                <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.privacyPolicy && this.state.privacyPolicy[0], 'shortDescription', this.state.lang ? this.state.lang : this.props.lang) }}></div>

                                {/*
                                <div className="policy-content">
                                    <p>{"Curoflow värdesätter din personliga integritet. I denna integritetspolicy informerar vi dig om hur vi hanterar de personuppgifter vi samlar in eller erhåller via vår webbplats och på andra sätt. Curoflow Technology AB, org. nr. 559123-3654, ('Curoflow') är personuppgiftsansvarig.".translate(this.props.lang)}
                                    <br />  <br />
                                    {"Vi behandlar alltid dina personuppgifter i enlighet med gällande dataskyddslagstiftning, inklusive den allmänna dataskyddsförordningen 2016/679 ('GDPR'). För information om vilka cookies vi använder på vår webbplats, se vår".translate(this.props.lang)}<span> <a href="https://curoflow.se/cookies">{'Cookie policy'.translate(this.props.lang)}</a></span></p>                    
                                    <h2>{'Vems och vilka personuppgifter behandlar vi?'.translate(this.props.lang)}</h2>
                                    <p>{'Vi behandlar dina personuppgifter i följande fall:'.translate(this.props.lang)}</p>
                                    <ul>
                                        <li>{'När du är kontaktperson hos kund eller annan intressent i samband med att vi utför uppdrag inom vår konsultverksamhet, eller som vi behandlar när uppdraget förbereds, utförs eller administreras. Detta inkluderar även kontaktpersoner hos potentiella kunder, andra rådgivare eller personer som vi önskar att kommunicera med för att informera om våra tjänster.'.translate(this.props.lang)}</li>
                                        <li>{'När du är kontaktperson för en till oss potentiell kund eller i annat ärende kontaktar oss via kontaktformulär på vår webbplats, eller direkt till de mejladresser Curoflow har angivet som sina. Detta inkluderar även andra rådgivare eller personer som vi önskar att kommunicera med för att informera om våra tjänster.'.translate(this.props.lang)}</li>
                                        <li>{'När du är leverantör, samarbetspartner eller annan part som utför, eller kan komma att utföra, tjänster åt oss.'.translate(this.props.lang)}</li>
                                        <li>{'När du anmäler dig och/eller deltar i något av våra evenemang, seminarium eller liknande.'.translate(this.props.lang)}</li>
                                        <li>{'När du besöker vår webbplats och, i förekommande fall, kontaktar oss via vårt kontaktformulär eller anmäler dig till vårt nyhetsbrev.'.translate(this.props.lang)}</li>
                                    </ul>                                
                                    <p>{'Personuppgifterna omfattar vanligtvis namn, kontaktuppgifter och titel, men i förekommande fall även bankuppgifter. Vi behandlar dessa personuppgifter för att utföra och administrera kontakten med dig, för att tillvarata dina eller våra intressen, hantera leverantörsförhållanden samt för redovisnings- och faktureringsändamål. Därutöver kan uppgifterna utgöra underlag för intern marknads- och klientanalys och statistik, riskhantering och för metod- och affärsutveckling. Personuppgifterna kan även komma att användas för marknadsföringsåtgärder.'.translate(this.props.lang)}
                                    <br />  <br />
                                    {'I samband med evenemang och seminarium kan vi komma att behandla uppgifter om allergier och matpreferenser samt, i vissa fall, bilder och inspelat material på plats. Vi behandlar personuppgifterna för att kunna anordna evenemang och seminarier, besvara din förfrågan om kontakt och/eller för marknadsföringsändamål. På vår webbplats använder vi även cookies för att optimera Webbplatsbesökares upplevelse, se vår'.translate(this.props.lang)} <span><a href="https://curoflow.se/cookies">{'Cookie Policy'.translate(this.props.lang)}</a></span> {'för mer information.'.translate(this.props.lang)}
                                    <br />  <br />
                                    {'Personuppgifterna härrör från dig själv eller din arbetsgivare, medan andra uppgifter kan uppkomma inom ramen för utförande av våra tjänster. I förekommande fall samlar vi in och lagrar personuppgifter från externa källor som offentliga bolags- och adressregister, sökbara och offentliga webbplatser och/eller från kreditupplysningsföretag.'.translate(this.props.lang)}</p>

                                    <h2>{'Rättslig grund för behandlingen'.translate(this.props.lang)}</h2>
                                    <p>{'Behandlingen av personuppgifter sker när det är nödvändigt för oss för att vi ska kunna fullgöra vårt avtal med dig eller för att kunna vidta åtgärder innan avtal ingås.'.translate(this.props.lang)}
                                    <br />  <br />
                                    {'Deltagares och Webbplatsbesökares personuppgifter behandlas för att uppfylla ett berättigat intresse, så som att bjuda in till evenemang eller utskick av nyhetsbrev, där vi bedömt att det berättigade intresset väger tyngre än den registrerades intressen eller grundläggande rättigheter och friheter (intresseavvägning).'.translate(this.props.lang)}</p>
                                   
                                    <h2>{'Lagring och utlämning av personuppgifter'.translate(this.props.lang)}</h2>
                                    <p>{'Vi sparar personuppgifter under den period det är nödvändigt för det aktuella ändamålet för vilket vi behandlar uppgifterna. Om du avanmäler dig för nyhetsbrev kommer uppgifterna, till den del de sparats för sådana ändamål, att raderas. Efter deltagande i evenemang eller seminarium behåller vi de personuppgifter som behandlades för de angivna ändamålen personuppgifter i ett (1) år innan vi raderar dem. Vi vill dock uppmärksamma dig på vid radering av dina personuppgifter för viss behandling kan vi komma att behålla personuppgifterna för andra ändamål, t.ex. om du fortfarande är kund eller leverantör.'.translate(this.props.lang)}</p>
                                    <h2>{'Överföring till land utanför EU/EES'.translate(this.props.lang)}</h2>   
                                    <p>{'Curoflow kan anlita externa samarbetspartners och leverantörer att utföra uppgifter för vår räkning, t ex för att tillhandahålla IT-tjänster, analyser eller statistik. Utförandet av dessa tjänster kan innebära att våra samarbetspartners, både inom och utanför EU/EES, får tillgång till era personuppgifter.'.translate(this.props.lang)}
                                    <br />  <br />
                                    {'Samarbetspartners som hanterar personuppgifter för Curoflow:s räkning undertecknar alltid avtal med oss för att säkerställa en hög skyddsnivå för era personuppgifter. I förhållande till samarbetspartners utanför EU/EES vidtas i nödvändiga fall dessutom särskilda skyddsåtgärder, t.ex. genom att vi ingår avtal som inkluderar standardavtalsklausuler för dataöverföring i enlighet med EU-kommissionens beslut och som finns tillgängliga på EU-kommissionens hemsida.'.translate(this.props.lang)}
                                    <br />  <br />
                                    <span style={{ fontWeight: 600 }}>{'Förtydligande:'.translate(this.props.lang)} </span>
                                    {'Denna integritetspolicy avser att beskriva det sätt vi hanterar personuppgifter från våra befintliga eller potentiella kunder. De personuppgifter (från vårdpersonal, administratörer och patienter) som hanteras i Curoflows medicintekniska plattform hanteras inom EU/EES och utan risk för överföring utanför EU/EES.'.translate(this.props.lang)}</p>
                               
                               
                                    <h2>{'Dina rättigheter'.translate(this.props.lang)}</h2>
                                    <p>{'Nedan följer en lista över de rättigheter du har avseende vår behandling av dina personuppgifter. Rättigheterna är inte absoluta och en begäran om utövande av rättigheterna resulterar därför inte alltid i någon åtgärd.'.translate(this.props.lang)}</p>
                                    <p>{'Dina rättigheter i GDPR inkluderar:'.translate(this.props.lang)}</p>
                                    <ul>
                                        <li>{'Rätt till tillgång – Enligt artikel 15 GDPR har du rätt att få tillgång till dina personuppgifter samt viss information rörande behandlingen av dem. Den informationen framgår av detta dokument.'.translate(this.props.lang)}</li>
                                        <li>{'Rätt till rättelse – Enligt artikel 16 GDPR har du rätt att få felaktiga personuppgifter rättade samt att komplettera ofullständiga personuppgifter.'.translate(this.props.lang)}</li>
                                        <li>{"Rätt till radering – Under vissa omständigheter har du enligt artikel 17 GDPR rätt att få personuppgifterna raderade. Detta är den så kallade 'rätten att bli bortglömd'.".translate(this.props.lang)}</li>
                                        <li>{'Rätt till begränsning av behandling – Under vissa omständigheter har du enligt artikel 18 GDPR rätt att begränsa behandlingen som vi vidtar.'.translate(this.props.lang)}</li>
                                        <li>{'Rätt till dataportabilitet – Enligt artikel 20 GDPR har du rätt att få ut personuppgifterna (eller få dem överförda till en annan personuppgiftsansvarig) i ett strukturerat, allmänt använt och maskinläsbart format.'.translate(this.props.lang)}</li>
                                        <li>{'Rätt att göra invändningar – Enligt artikel 21 GDPR har du rätt att invända mot särskilda personuppgiftsbehandlingar.'.translate(this.props.lang)}</li>
                                    </ul>    
                                    <h2>{'Kontaktinformation'.translate(this.props.lang)}</h2>
                                    <p>{'Kontakta oss på'.translate(this.props.lang)} <span> <a href="mailto:integritet@curoflow.se">{'integritet@curoflow.se'.translate(this.props.lanmg)}</a></span> {'om du har några frågor angående vår personuppgiftsbehandling eller önskar utöva dina rättigheter enligt ovan. Om du är missnöjd med vår behandling finns möjlighet att framföra klagomål hos Integritetsskyddsmyndigheten via www.imy.se. Du kan även vända dig till tillsynsmyndigheten i det land där du bor eller arbetar.'.translate(this.props.lang)}</p>
                                </div>
                                */}
                            </Col>
                        </Row>
                    </Container>
                </div>

            </div>
        );
    }
}

export default Page(PrivacyPolicy);

