import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';
import info from '../assets/svg/info.svg'
import Isvg from 'react-inlinesvg';

/**
* Info modal 
* @author   Milan Stanojevic
*/
class InfoModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        if(this.props.isOpen){
            this.setState({
                isOpen: this.props.isOpen
            })
        }
    }

    render() {
        return (

            <Modal isOpen={this.state.isOpen} toggle={this.props.toggle} centered size={this.props.size ? this.props.size  : null}>
                {/* {this.props.close ? 

                <ModalHeader style={{margin: 'auto'}}>
                    {this.props.header}
                </ModalHeader>

                :
               
                <ModalHeader style={{margin: 'auto'}}>
                    {this.props.header}
                </ModalHeader>
                 } */}
                <ModalBody className="delete-modal">
                    <img style={{width:100, height:100, 'margin-bottom':15}} src={this.props.image} />
                    <div className='message-info'>
                    {
                        this.props.info 
                    }
                    </div>
                    
                </ModalBody>
                <ModalFooter>
                    {this.props.buttons}
                </ModalFooter>
            </Modal>
        )
    }
}

export default InfoModal;