import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import Link from '../link';

import Check from './fields/check';
import Text from './fields/text';
import Select from './fields/select';
import Textarea from './fields/textarea';
import Phone from './fields/phone';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    Label
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderCheckField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },

}) => (

    <Check
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        // multilang={multilang}
        // lang={lang}

        {...input}
    />
)

const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    children,
    additionalAction,
    scope,
}) => (

    <Select
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        additionalAction={additionalAction}
        scope={scope}
        {...input}
        children={children}
    />
)

const renderTextField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type
}) => (

    <Text
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        {...input}
    />
)

const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

    <Textarea
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)

const renderPhoneField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

    <Phone
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)


class form extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
        }
    }



    render() {

        const { handleSubmit, pristine, reset, submitting, menu, supportCategories } = this.props;

        return (
            <form onSubmit={handleSubmit} className="contact-form">
                {this.props.showTitle ? <h4 className='titleFrom'>{"Contact our support".translate(this.props.lang)}</h4> : <h4 className='titleFrom'>{"Book a demo of Curoflow".translate(this.props.lang)}</h4>

                }
                <div></div>
                <Row>
                    <Col lg="6">
                        <div className="field-wrap">
                            {/*
                            <Field
                                name="support"
                                component={renderSelectField}
                                label={"Support".translate(this.props.lang)}
                                //validate={[required]}
                            >
                             {
                                 this.props.supportCategories && this.props.supportCategories.map((supportCategorie, idx) => {
                                     return (
                                         <option value={supportCategorie.name}>
                                             {supportCategorie.name}
                                         </option>
                                     )
                                 })
                             }
                        
                            </Field>
                            */}
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="name"
                                component={renderTextField}
                                label={"Namn".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="field-wrap">
                            <Field
                                name="title"
                                component={renderTextField}
                                label={"Titel".translate(this.props.lang)}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="field-wrap">
                            <Field
                                name="organization"
                                component={renderTextField}
                                label={"Organisation".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="field-wrap">
                            <Field
                                name="email"
                                type="email"
                                component={renderTextField}
                                label={"E-mail".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="field-wrap custom-phone-number">
                            <Field
                                name="text"
                                component={renderTextField}
                                label={"Telefonnummer".translate(this.props.lang)}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="message"
                                component={renderTextareaField}
                                label={"Meddelande".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap label-set">
                             <Field
                                name="checked"
                                component={renderCheckField}
                                placeholder=""
                                label={<Label>{'Jag godkänner Curoflows'.translate(this.props.lang)}  
                                <span> <Link lang={this.props.lang} to="/integritetspolicy" target="_blank">{'integritetspolicy'.translate(this.props.lang)}</Link> </span></Label>}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12" className="btn-wrap">
                        <button type="submit" className="button-clasic-black">{'Skicka'.translate(this.props.lang)}</button>
                    </Col>
                </Row>
            </form>

        )
    }
}

export default reduxForm({
    form: 'supportForm2'  // a unique identifier for this form
})(form)
