import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Header from '../components/header';
import Nav from '../components/nav';
import Footer from '../components/footer';
import SupportForm from '../components/forms/supportForm';


export const DefaultLayout = (Wrapped) => (props) => {
    // console.log(props)
    return (
        <div>
           
            {
                props[0] && props[0].location && props[0].location.pathname != '/prijava' && props[0].location.pathname != '/app' &&  props[0].location.pathname != '/form-submitted'?
                    
                    <>
                        <Header {...props} />
                        <Nav {...props} />
                        <Wrapped {...props} />
                        <Footer {...props} />
                    </>
                    :
                    <>
                        <Wrapped {...props} />
                        
                    </>

            }

        </div>
    );
};

export default DefaultLayout;