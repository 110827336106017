import { useEffect } from "react"

/* 
gtag('event', 'page_view', {
        'page_title': document.title, // Naslov trenutne stranice
        'page_location': url,         // URL trenutne stranice
        'page_path': url              // Putanja trenutne stranice
    });
*/

const TrackUrl = () => {

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }

            const dataObj = {
                'page_title': document?.title,
                'page_location': window.location.pathname,
                'page_path': window.location.pathname
            };
            console.log('sending gtag data ...', dataObj);

            gtag('event', 'page_view', dataObj);
        }
    }, [(typeof window !== 'undefined' ? window?.location?.pathname : undefined)])

    return (
        null
    )
}

export default TrackUrl