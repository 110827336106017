import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import { API_ENDPOINT } from "../constants";
import moment from 'moment';
import icon from '../assets/svg/icon.svg'

import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

class CustomDropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dropdownOpen: false,
            children: []
        };
    }

    toggle = () => {
        fetch(API_ENDPOINT + '/data/pages/' + this.props.apiName, {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            },
        }).then(parseJSON).then(({result, status}) => {
            
            if (result.error) {
                this.setState({ error: result.error })
            } else {
                this.setState({
                    dropdownOpen: !this.state.dropdownOpen,
                    children: [...result]
                })
            }
        })
        
    }

    render() {
        return (
            <div >
                <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.toggle()} >
                    <DropdownToggle className='custom-dropdown'>{this.props.label} <span className={this.state.dropdownOpen && 'rotate-arrow'}><Isvg src={icon}/></span></DropdownToggle>
                    <DropdownMenu >
                        {
                            this.state.children && this.state.children.map(item => {
                                return (
                                    <DropdownItem onClick={() => {
                                        if(this.props.pathName)
                                        this.props[0].history.push('/' +this.props.pathName +  item.alias[this.props.lang])
                                    }}>{item.name && item.name[this.props.lang]}</DropdownItem>

                                )
                            })
                        }
                    </DropdownMenu>
                </Dropdown>
            </div>
        );
    }
}

export default CustomDropdown;

