export const HTML_CHARACTERS = {
    '&larr;': '←',
    '&uarr;': '↑',
    '&rarr;': '→',
    '&darr;': '↓',
    '&harr;': '↔',
    '&varr;': '↕',
    '&nwarr;': '↖',
    '&nearr;': '↗',
    '&searr;': '↘',
    '&swarr;': '↙',
    '&nlarr;': '↚',
    '&nrarr;': '↛',
    '&larrw;': '↜',
    '&rarrw;': '↝',
    '&Larr;': '↞',
    '&Uarr;': '↟',
    '&Rarr;': '↠',
    '&Darr;': '↡',
    '&larrtl;': '↢',
    '&rarrtl;': '↣',
    '&mapstoleft;': '↤',
    '&mapstoup;': '↥',
    '&mapstoright;': '↦',
    '&mapstodown;': '↧',
    '&larrhk;': '↩',
    '&rarrhk;': '↪',
    '&larrlp;': '↫',
    '&rarrlp;': '↬',
    '&harrw;': '↭',
    '&nharr;': '↮',
    '&lsh;': '↰',
    '&rsh;': '↱',
    '&ldsh;': '↲',
    '&rdsh;': '↳',
    '&crarr;': '↵',
    '&cularr;': '↶',
    '&curarr;': '↷',
    '&olarr;': '↺',
    '&orarr;': '↻',
    '&lharu;': '↼',
    '&lhard;': '↽',
    '&uharr;': '↾',
    '&uharl;': '↿',
    '&rharu;': '⇀',
    '&rhard;': '⇁',
    '&dharr;': '⇂',
    '&dharl;': '⇃',
    '&rlarr;': '⇄',
    '&udarr;': '⇅',
    '&lrarr;': '⇆',
    '&llarr;': '⇇',
    '&uuarr;': '⇈',
    '&rrarr;': '⇉',
    '&ddarr;': '⇊',
    '&lrhar;': '⇋',
    '&rlhar;': '⇌',
    '&nlArr;': '⇍',
    '&nhArr;': '⇎',
    '&nrArr;': '⇏',
    '&lArr;': '⇐',
    '&uArr;': '⇑',
    '&rArr;': '⇒',
    '&dArr;': '⇓',
    '&hArr;': '⇔',
    '&vArr;': '⇕',
    '&nwArr;': '⇖',
    '&neArr;': '⇗',
    '&seArr;': '⇘',
    '&swArr;': '⇙',
    '&lAarr;': '⇚',
    '&rAarr;': '⇛',
    '&ziglarr;': '⇜',
    '&zigrarr;': '⇝',
    '&larrb;': '⇤',
    '&rarrb;': '⇥',
    '&duarr;': '⇵',
    '&loarr;': '⇽',
    '&roarr;': '⇾',
    '&hoarr;': '⇿',
    '&xlarr;': '⟵',
    '&xrarr;': '⟶',
    '&xharr;': '⟷',
    '&xlArr;': '⟸',
    '&xrArr;': '⟹',
    '&xhArr;': '⟺',
    '&xmap;': '⟼',
    '&dzigrarr;': '⟿',
    '&nvlArr;': '⤂',
    '&nvrArr;': '⤃',
    '&nvHarr;': '⤄',
    '&Map;': '⤅',
    '&lbarr;': '⤌',
    '&rbarr;': '⤍',
    '&lBarr;': '⤎',
    '&rBarr;': '⤏',
    '&RBarr;': '⤐',
    '&DDotrahd;': '⤑',
    '&UpArrowBar;': '⤒',
    '&DownArrowBar;': '⤓',
    '&Rarrtl;': '⤖',
    '&latail;': '⤙',
    '&ratail;': '⤚',
    '&lAtail;': '⤛',
    '&rAtail;': '⤜',
    '&larrfs;': '⤝',
    '&rarrfs;': '⤞',
    '&larrbfs;': '⤟',
    '&rarrbfs;': '⤠',
    '&nwarhk;': '⤣',
    '&nearhk;': '⤤',
    '&searhk;': '⤥',
    '&swarhk;': '⤦',
    '&nwnear;': '⤧',
    '&nesear;': '⤨',
    '&seswar;': '⤩',
    '&swnwar;': '⤪',
    '&rarrc;': '⤳',
    '&cudarrr;': '⤵',
    '&ldca;': '⤶',
    '&rdca;': '⤷',
    '&cudarrl;': '⤸',
    '&larrpl;': '⤹',
    '&curarrm;': '⤼',
    '&cularrp;': '⤽',
    '&rarrpl;': '⥅',
    '&harrcir;': '⥈',
    '&Uarrocir;': '⥉',
    '&lurdshar;': '⥊',
    '&ldrushar;': '⥋',
    '&LeftRightVector;': '⥎',
    '&RightUpDownVector;': '⥏',
    '&DownLeftRightVector;': '⥐',
    '&LeftUpDownVector;': '⥑',
    '&LeftVectorBar;': '⥒',
    '&RightVectorBar;': '⥓',
    '&RightUpVectorBar;': '⥔',
    '&RightDownVectorBar;': '⥕',
    '&DownLeftVectorBar;': '⥖',
    '&DownRightVectorBar;': '⥗',
    '&LeftUpVectorBar;': '⥘',
    '&LeftDownVectorBar;': '⥙',
    '&LeftTeeVector;': '⥚',
    '&RightTeeVector;': '⥛',
    '&RightUpTeeVector;': '⥜',
    '&RightDownTeeVector;': '⥝',
    '&DownLeftTeeVector;': '⥞',
    '&DownRightTeeVector;': '⥟',
    '&LeftUpTeeVector;': '⥠',
    '&LeftDownTeeVector;': '⥡',
    '&lHar;': '⥢',
    '&uHar;': '⥣',
    '&rHar;': '⥤',
    '&dHar;': '⥥',
    '&luruhar;': '⥦',
    '&ldrdhar;': '⥧',
    '&ruluhar;': '⥨',
    '&rdldhar;': '⥩',
    '&lharul;': '⥪',
    '&llhard;': '⥫',
    '&rharul;': '⥬',
    '&lrhard;': '⥭',
    '&udhar;': '⥮',
    '&duhar;': '⥯',
    '&RoundImplies;': '⥰',
    '&erarr;': '⥱',
    '&simrarr;': '⥲',
    '&larrsim;': '⥳',
    '&rarrsim;': '⥴',
    '&rarrap;': '⥵',
    '&ltlarr;': '⥶',
    '&gtrarr;': '⥸',
    '&subrarr;': '⥹',
    '&suplarr;': '⥻',
    '&lfisht;': '⥼',
    '&rfisht;': '⥽',
    '&ufisht;': '⥾',
    '&dfisht;': '⥿',
    '&dollar;': '$',
    '&cent;': '¢',
    '&pound;': '£',
    '&euro;': '€',
    '&yen;': '¥',
    '&curren;': '¤',
    '&Agrave;': 'à',
    '&Aacute;': 'á',
    '&Acirc;': 'â',
    '&Atilde;': 'ã',
    '&Auml;': 'ä',
    '&Aring;': 'å',
    '&AElig;': 'æ',
    '&Ccedil;': 'ç',
    '&Egrave;': 'è',
    '&Eacute;': 'é',
    '&Ecirc;': 'ê',
    '&Euml;': 'ë',
    '&Lgrave;': 'ì',
    '&Lacute;': 'ĺ',
    '&Lcirc;': 'î',
    '&Luml;': 'ï',
    '&ETH;': 'ð',
    '&Ntilde;': 'ñ',
    '&Ograve;': 'ò',
    '&Oacute;': 'ó',
    '&Ocirc;': 'ô',
    '&Otilde;': 'õ',
    '&Ouml;': 'ö',
    '&Oslash;': 'ø',
    '&Ugrave;': 'ù',
    '&Uacute;': 'ú',
    '&Ucirc;': 'û',
    '&Uuml;': 'ü',
    '&Yacute;': 'ý',
    '&THORN;': 'þ',
    '&szlig;': 'ß',
    '&agrave;': 'à',
    '&aacute;': 'á',
    '&acirc;': 'â',
    '&atilde;': 'ã',
    '&auml;': 'ä',
    '&aring;': 'å',
    '&aelig;': 'æ',
    '&ccedil;': 'ç',
    '&egrave;': 'è',
    '&eacute;': 'é',
    '&ecirc;': 'ê',
    '&euml;': 'ë',
    '&igrave;': 'ì',
    '&iacute;': 'í',
    '&icirc;': 'î',
    '&iuml;': 'ï',
    '&eth;': 'ð',
    '&ntilde;': 'ñ',
    '&ograve;': 'ò',
    '&oacute;': 'ó',
    '&ocirc;': 'ô',
    '&otilde;': 'õ',
    '&ouml;': 'ö',
    '&oslash;': 'ø',
    '&ugrave;': 'ù',
    '&uacute;': 'ú',
    '&ucirc;': 'û',
    '&uuml;': 'ü',
    '&yacute;': 'ý',
    '&thorn;': 'þ',
    '&yuml;': 'ÿ',
    '&Amacr;': 'ā',
    '&amacr;': 'ā',
    '&Abreve;': 'ă',
    '&abreve;': 'ă',
    '&Aogon;': 'ą',
    '&aogon;': 'ą',
    '&Cacute;': 'ć',
    '&cacute;': 'ć',
    '&Ccirc;': 'ĉ',
    '&ccirc;': 'ĉ',
    '&Cdot;': 'ċ',
    '&cdot;': 'ċ',
    '&Ccaron;': 'č',
    '&ccaron;': 'č',
    '&Dcaron;': 'ď',
    '&dcaron;': 'ď',
    '&Dstrok;': 'đ',
    '&dstrok;': 'đ',
    '&Emacr;': 'ē',
    '&emacr;': 'ē',
    '&Edot;': 'ė',
    '&edot;': 'ė',
    '&Eogon;': 'ę',
    '&eogon;': 'ę',
    '&Ecaron;': 'ě',
    '&ecaron;': 'ě',
    '&Gcirc;': 'ĝ',
    '&gcirc;': 'ĝ',
    '&Gbreve;': 'ğ',
    '&gbreve;': 'ğ',
    '&Gdot;': 'ġ',
    '&gdot;': 'ġ',
    '&Gcedil;': 'ģ',
    '&Hcirc;': 'ĥ',
    '&hcirc;': 'ĥ',
    '&Hstrok;': 'ħ',
    '&hstrok;': 'ħ',
    '&Itilde;': 'ĩ',
    '&itilde;': 'ĩ',
    '&Imacr;': 'ī',
    '&imacr;': 'ī',
    '&Iogon;': 'į',
    '&iogon;': 'į',
    '&Idot;': 'i̇',
    '&imath;': 'ı',
    '&IJlig;': 'ĳ',
    '&ijlig;': 'ĳ',
    '&Jcirc;': 'ĵ',
    '&jcirc;': 'ĵ',
    '&Kcedil;': 'ķ',
    '&kcedil;': 'ķ',
    '&kgreen;': 'ĸ',
    '&lacute;': 'ĺ',
    '&Lcedil;': 'ļ',
    '&lcedil;': 'ļ',
    '&Lcaron;': 'ľ',
    '&lcaron;': 'ľ',
    '&Lmidot;': 'ŀ',
    '&lmidot;': 'ŀ',
    '&Lstrok;': 'ł',
    '&lstrok;': 'ł',
    '&Nacute;': 'ń',
    '&nacute;': 'ń',
    '&Ncedil;': 'ņ',
    '&ncedil;': 'ņ',
    '&Ncaron;': 'ň',
    '&ncaron;': 'ň',
    '&napos;': 'ŉ',
    '&ENG;': 'ŋ',
    '&eng;': 'ŋ',
    '&Omacr;': 'ō',
    '&omacr;': 'ō',
    '&Odblac;': 'ő',
    '&odblac;': 'ő',
    '&OElig;': 'œ',
    '&oelig;': 'œ',
    '&Racute;': 'ŕ',
    '&racute;': 'ŕ',
    '&Rcedil;': 'ŗ',
    '&rcedil;': 'ŗ',
    '&Rcaron;': 'ř',
    '&rcaron;': 'ř',
    '&Sacute;': 'ś',
    '&sacute;': 'ś',
    '&Scirc;': 'ŝ',
    '&scirc;': 'ŝ',
    '&Scedil;': 'ş',
    '&scedil;': 'ş',
    '&Scaron;': 'š',
    '&scaron;': 'š',
    '&Tcedil;': 'ţ',
    '&tcedil;': 'ţ',
    '&Tcaron;': 'ť',
    '&tcaron;': 'ť',
    '&Tstrok;': 'ŧ',
    '&tstrok;': 'ŧ',
    '&Utilde;': 'ũ',
    '&utilde;': 'ũ',
    '&Umacr;': 'ū',
    '&umacr;': 'ū',
    '&Ubreve;': 'ŭ',
    '&ubreve;': 'ŭ',
    '&Uring;': 'ů',
    '&uring;': 'ů',
    '&Udblac;': 'ű',
    '&udblac;': 'ű',
    '&Uogon;': 'ų',
    '&uogon;': 'ų',
    '&Wcirc;': 'ŵ',
    '&wcirc;': 'ŵ',
    '&Ycirc;': 'ŷ',
    '&ycirc;': 'ŷ',
    '&Yuml;': 'ÿ',
    '&Zacute;': 'ź',
    '&zacute;': 'ź',
    '&Zdot;': 'ż',
    '&zdot;': 'ż',
    '&Zcaron;': 'ž',
    '&zcaron;': 'ž',
    '&DownBreve;': '̑',
    '&plus;': '+',
    '&minus;': '−',
    '&times;': '×',
    '&divide;': '÷',
    '&equals;': '=',
    '&ne;': '≠',
    '&plusmn;': '±',
    '&not;': '¬',
    '&lt;': '<',
    '&gt;': '>',
    '&deg;': '°',
    '&sup1;': '¹',
    '&sup2;': '²',
    '&sup3;': '³',
    '&fnof;': 'ƒ',
    '&percnt;': '%',
    '&permil;': '‰',
    '&pertenk;': '‱',
    '&forall;': '∀',
    '&comp;': '∁',
    '&part;': '∂',
    '&exist;': '∃',
    '&nexist;': '∄',
    '&empty;': '∅',
    '&nabla;': '∇',
    '&isin;': '∈',
    '&notin;': '∉',
    '&ni;': '∋',
    '&notni;': '∌',
    '&prod;': '∏',
    '&coprod;': '∐',
    '&sum;': '∑',
    '&mnplus;': '∓',
    '&plusdo;': '∔',
    '&setminus;': '∖',
    '&lowast;': '∗',
    '&compfn;': '∘',
    '&radic;': '√',
    '&prop;': '∝',
    '&infin;': '∞',
    '&angrt;': '∟',
    '&ang;': '∠',
    '&angmsd;': '∡',
    '&angsph;': '∢',
    '&mid;': '∣',
    '&nmid;': '∤',
    '&parallel;': '∥',
    '&npar;': '∦',
    '&and;': '∧',
    '&or;': '∨',
    '&cap;': '∩',
    '&cup;': '∪',
    '&int;': '∫',
    '&Int;': '∬',
    '&iiint;': '∭',
    '&conint;': '∮',
    '&Conint;': '∯',
    '&Cconint;': '∰',
    '&cwint;': '∱',
    '&cwconint;': '∲',
    '&awconint;': '∳',
    '&there4;': '∴',
    '&because;': '∵',
    '&ratio;': '∶',
    '&Colon;': '∷',
    '&minusd;': '∸',
    '&mDDot;': '∺',
    '&homtht;': '∻',
    '&sim;': '∼',
    '&bsim;': '∽',
    '&ac;': '∾',
    '&acd;': '∿',
    '&wreath;': '≀',
    '&nsim;': '≁',
    '&esim;': '≂',
    '&sime;': '≃',
    '&nsime;': '≄',
    '&cong;': '≅',
    '&simne;': '≆',
    '&ncong;': '≇',
    '&asymp;': '≈',
    '&nap;': '≉',
    '&approxeq;': '≊',
    '&apid;': '≋',
    '&bcong;': '≌',
    '&asympeq;': '≍',
    '&bump;': '≎',
    '&bumpe;': '≏',
    '&esdot;': '≐',
    '&eDot;': '≑',
    '&efDot;': '≒',
    '&erDot;': '≓',
    '&colone;': '≔',
    '&ecolon;': '≕',
    '&ecir;': '≖',
    '&cire;': '≗',
    '&wedgeq;': '≙',
    '&veeeq;': '≚',
    '&trie;': '≜',
    '&equest;': '≟',
    '&equiv;': '≡',
    '&nequiv;': '≢',
    '&le;': '≤',
    '&ge;': '≥',
    '&lE;': '≦',
    '&gE;': '≧',
    '&lnE;': '≨',
    '&gnE;': '≩',
    '&Lt;': '≪',
    '&Gt;': '≫',
    '&between;': '≬',
    '&NotCupCap;': '≭',
    '&nlt;': '≮',
    '&ngt;': '≯',
    '&nle;': '≰',
    '&nge;': '≱',
    '&lsim;': '≲',
    '&gsim;': '≳',
    '&nlsim;': '≴',
    '&ngsim;': '≵',
    '&lg;': '≶',
    '&gl;': '≷',
    '&ntlg;': '≸',
    '&ntgl;': '≹',
    '&pr;': '≺',
    '&sc;': '≻',
    '&prcue;': '≼',
    '&sccue;': '≽',
    '&prsim;': '≾',
    '&scsim;': '≿',
    '&npr;': '⊀',
    '&nsc;': '⊁',
    '&sub;': '⊂',
    '&sup;': '⊃',
    '&nsub;': '⊄',
    '&nsup;': '⊅',
    '&sube;': '⊆',
    '&supe;': '⊇',
    '&nsube;': '⊈',
    '&nsupe;': '⊉',
    '&subne;': '⊊',
    '&supne;': '⊋',
    '&cupdot;': '⊍',
    '&uplus;': '⊎',
    '&sqsub;': '⊏',
    '&sqsup;': '⊐',
    '&sqsube;': '⊑',
    '&sqsupe;': '⊒',
    '&sqcap;': '⊓',
    '&sqcup;': '⊔',
    '&oplus;': '⊕',
    '&ominus;': '⊖',
    '&otimes;': '⊗',
    '&osol;': '⊘',
    '&odot;': '⊙',
    '&ocir;': '⊚',
    '&oast;': '⊛',
    '&odash;': '⊝',
    '&plusb;': '⊞',
    '&minusb;': '⊟',
    '&timesb;': '⊠',
    '&sdotb;': '⊡',
    '&vdash;': '⊢',
    '&dashv;': '⊣',
    '&top;': '⊤',
    '&perp;': '⊥',
    '&models;': '⊧',
    '&vDash;': '⊨',
    '&Vdash;': '⊩',
    '&Vvdash;': '⊪',
    '&VDash;': '⊫',
    '&nvdash;': '⊬',
    '&nvDash;': '⊭',
    '&nVdash;': '⊮',
    '&nVDash;': '⊯',
    '&prurel;': '⊰',
    '&vltri;': '⊲',
    '&vrtri;': '⊳',
    '&ltrie;': '⊴',
    '&rtrie;': '⊵',
    '&origof;': '⊶',
    '&imof;': '⊷',
    '&mumap;': '⊸',
    '&hercon;': '⊹',
    '&intcal;': '⊺',
    '&veebar;': '⊻',
    '&barvee;': '⊽',
    '&angrtvb;': '⊾',
    '&lrtri;': '⊿',
    '&xwedge;': '⋀',
    '&xvee;': '⋁',
    '&xcap;': '⋂',
    '&xcup;': '⋃',
    '&diamond;': '⋄',
    '&sdot;': '⋅',
    '&Star;': '⋆',
    '&divonx;': '⋇',
    '&bowtie;': '⋈',
    '&ltimes;': '⋉',
    '&rtimes;': '⋊',
    '&lthree;': '⋋',
    '&rthree;': '⋌',
    '&bsime;': '⋍',
    '&cuvee;': '⋎',
    '&cuwed;': '⋏',
    '&Sub;': '⋐',
    '&Sup;': '⋑',
    '&Cap;': '⋒',
    '&Cup;': '⋓',
    '&fork;': '⋔',
    '&epar;': '⋕',
    '&ltdot;': '⋖',
    '&gtdot;': '⋗',
    '&Ll;': '⋘',
    '&Gg;': '⋙',
    '&leg;': '⋚',
    '&gel;': '⋛',
    '&cuepr;': '⋞',
    '&cuesc;': '⋟',
    '&nprcue;': '⋠',
    '&nsccue;': '⋡',
    '&nsqsube;': '⋢',
    '&nsqsupe;': '⋣',
    '&lnsim;': '⋦',
    '&gnsim;': '⋧',
    '&prnsim;': '⋨',
    '&scnsim;': '⋩',
    '&nltri;': '⋪',
    '&nrtri;': '⋫',
    '&nltrie;': '⋬',
    '&nrtrie;': '⋭',
    '&vellip;': '⋮',
    '&ctdot;': '⋯',
    '&utdot;': '⋰',
    '&dtdot;': '⋱',
    '&disin;': '⋲',
    '&isinsv;': '⋳',
    '&isins;': '⋴',
    '&isindot;': '⋵',
    '&notinvc;': '⋶',
    '&notinvb;': '⋷',
    '&isinE;': '⋹',
    '&nisd;': '⋺',
    '&xnis;': '⋻',
    '&nis;': '⋼',
    '&notnivc;': '⋽',
    '&notnivb;': '⋾',
    '&frac14;': '¼',
    '&frac12;': '½',
    '&frac34;': '¾',
    '&frac13;': '⅓',
    '&frac23;': '⅔',
    '&frac15;': '⅕',
    '&frac25;': '⅖',
    '&frac35;': '⅗',
    '&frac45;': '⅘',
    '&frac16;': '⅙',
    '&frac56;': '⅚',
    '&frac18;': '⅛',
    '&frac38;': '⅜',
    '&frac58;': '⅝',
    '&frac78;': '⅞',
    '&excl;': '!',
    '&quot;': '"',
    '&num;': '#',
    '&amp;': '&',
    '&apos;': "'",
    '&lpar;': '(',
    '&rpar;': ')',
    '&ast;': '*',
    '&comma;': ',',
    '&period;': '.',
    '&sol;': '/',
    '&colon;': ':',
    '&semi;': ';',
    '&quest;': '?',
    '&commat;': '@',
    '&lbrack;': '[',
    '&bsol;': '\\',
    '&rbrack;': ']',
    '&Hat;': '^',
    '&lowbar;': '_',
    '&grave;': '`',
    '&lbrace;': '{',
    '&vert;': '|',
    '&rbrace;': '}',
    '&tilde;': '~',
    '&nbsp;': '',
    '&iexcl;': '¡',
    '&brvbar;': '¦',
    '&sect;': '§',
    '&uml;': '¨',
    '&copy;': '©',
    '&ordf;': 'ª',
    '&laquo;': '«',
    '&shy;': '­',
    '&reg;': '®',
    '&macr;': '¯',
    '&acute;': '´',
    '&micro;': 'µ',
    '&para;': '¶',
    '&middot;': '·',
    '&cedil;': '¸',
    '&ordm;': 'º',
    '&raquo;': '»',
    '&iquest;': '¿',
    '&hyphen;': '‐',
    '&ndash;': '–',
    '&mdash;': '—',
    '&horbar;': '―',
    '&Vert;': '‖',
    '&lsquo;': '‘',
    '&rsquo;': '’',
    '&sbquo;': '‚',
    '&ldquo;': '“',
    '&rdquo;': '”',
    '&bdquo;': '„',
    '&dagger;': '†',
    '&Dagger;': '‡',
    '&bull;': '•',
    '&nldr;': '‥',
    '&hellip;': '…',
    '&prime;': '′',
    '&Prime;': '″',
    '&tprime;': '‴',
    '&bprime;': '‵',
    '&lsaquo;': '‹',
    '&rsaquo;': '›',
    '&oline;': '‾',
    '&caret;': '⁁',
    '&hybull;': '⁃',
    '&frasl;': '⁄',
    '&bsemi;': '⁏',
    '&qprime;': '⁗',
    '&trade;': '™',
    '&Copf;': 'ℂ',
    '&incare;': '℅',
    '&gscr;': 'ℊ',
    '&hamilt;': 'ℋ',
    '&Hfr;': 'ℌ',
    '&Hopf;': 'ℍ',
    '&planckh;': 'ℎ',
    '&planck;': 'ℏ',
    '&Iscr;': 'ℐ',
    '&image;': 'ℑ',
    '&Lscr;': 'ℒ',
    '&ell;': 'ℓ',
    '&Nopf;': 'ℕ',
    '&numero;': '№',
    '&copysr;': '℗',
    '&weierp;': '℘',
    '&Popf;': 'ℙ',
    '&Qopf;': 'ℚ',
    '&Rscr;': 'ℛ',
    '&real;': 'ℜ',
    '&Ropf;': 'ℝ',
    '&rx;': '℞',
    '&Zopf;': 'ℤ',
    '&mho;': '℧',
    '&Zfr;': 'ℨ',
    '&iiota;': '℩',
    '&bernou;': 'ℬ',
    '&Cfr;': 'ℭ',
    '&escr;': 'ℯ',
    '&Escr;': 'ℰ',
    '&Fscr;': 'ℱ',
    '&Mscr;': 'ℳ',
    '&oscr;': 'ℴ',
    '&alefsym;': 'ℵ',
    '&beth;': 'ℶ',
    '&gimel;': 'ℷ',
    '&daleth;': 'ℸ',
    '&DD;': 'ⅅ',
    '&dd;': 'ⅆ',
    '&ee;': 'ⅇ',
    '&ii;': 'ⅈ',
    '&starf;': '★',
    '&star;': '☆',
    '&phone;': '☎',
    '&male;': '♂',
    '&spades;': '♠',
    '&clubs;': '♣',
    '&hearts;': '♥',
    '&diams;': '♦',
    '&sung;': '♪',
    '&flat;': '♭',
    '&natural;': '♮',
    '&sharp;': '♯',
    '&check;': '✓',
    '&cross;': '✗',
    '&malt;': '✠',
    '&sext;': '✶',
    '&VerticalSeparator;': '❘',
    '&lbbrk;': '❲',
    '&rbbrk;': '❳'
}