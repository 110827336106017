import React, { Component } from 'react';


import { FormGroup, Label } from 'reactstrap';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';


class Text extends Component {
    constructor(props) {
        super(props);

        this.state = {
            focus: false,

        };
    }


    render() {
        // console.log(this.state.focus)
        return (
            <FormGroup check={this.props.type == 'checkbox'}>
                {this.props.label ? <Label check={this.props.type == 'checkbox'} size="sm" className={this.props.error ? "required" : ""}>{this.props.label}{this.props.haveValidation ? <span className="required-label-span">*</span> : null}</Label> : null}
                <div className={this.state.focus && this.props.error ? 'required-error-phone-hover required-error-phone' : this.props.error && !this.state.focus ? 'required-error-phone' :  ''}>
                    <PhoneInput
                        onFocus={() => { this.setState({ focus: true }) }}
                        onBlur={() => { this.setState({ focus: false }) }}
                        disabled={this.props.disabled}
                        country={'se'}
                        value={this.props.value}
                        enableLongNumbers={true}
                        onChange={(e, data) => {
                            // console.log(data)
                            if (e.indexOf('+') !== 0) {
                                e = '+' + e;
                            };

                            if (e.indexOf(data.dialCode + '0') == 1) {
                                e = e.replace(data.dialCode + '0', data.dialCode)
                            }

                            this.props.onChange(e)
                        }}

                    />
                </div>


                {this.props.error ? <div className="required-error">{this.props.error}</div> : null}
            </FormGroup>
        );
    }
}

export default Text;