import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { API_ENDPOINT } from "../constants";
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Input
} from 'reactstrap';

import ContactForm from '../components/forms/contactForm';
import arrowDown from '../assets/svg/arrow-down.svg';
import p1 from '../assets/images/p1.png';
import p2 from '../assets/images/p2.png';
import p3 from '../assets/images/p3.png';
import p4 from '../assets/images/p4.png';
import p5 from '../assets/images/p5.png';
import p6 from '../assets/images/p6.png';
import p7 from '../assets/images/p7.png';
import chat from '../assets/images/chat.png';
import ChatForm from '../components/forms/chatForm';


var striptags = require('striptags');

function parseJSON(response) {
    return response.json().then((json) => {
      return {
        result: json,
        status: response.status,
      };
    });
  }

class OurSolutions extends Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.player = React.createRef(); 

        this.state = {
            promotedProjects: [],
            poolActive: false,
            chatForm: false,
            yScroll2: 0,
            forPartners: [],
            ...props.initialData
        };
    }

    listenToScroll = () => {
        const yScroll2 =
            document.body.scrollTop || document.documentElement.scrollTop


        this.setState({
            yScroll2: yScroll2,
        })
    }

    sendMessage1 = (data) => {
        if(data.checked) {
            data.lang = this.props.lang
            this.setState({
                loading1: true
            }, () => {
    
                fetch(API_ENDPOINT + '/data/contact/new', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({data})   
                }).then(parseJSON).then(({result, status}) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                    }
                })
            })
        }
    }

    sendMessage2 = (data) => {
        if(data.checked) {
            data.lang = this.props.lang
            this.setState({
                loading2: true
            }, () => {
    
                setTimeout(() => {
                    this.player.current.pause()
                }, 1060)
    
                fetch(API_ENDPOINT + '/data/contact/new', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({data})   
                }).then(parseJSON).then(({result, status}) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                        const path = 'thank-you'.translate(this.props.lang);
                        this.props[0].history.push(`/${path}`);
                    }
                })
            })
        }
    }

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }
           
        this.updateWindowDimensions();
        window.addEventListener('scroll', this.listenToScroll)

    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            // this.get()
        }
    }
    
    render() {
      

        const percentage1 = 78;
        const percentage2 = 52;
        const percentage3 = 83;

        return (
            <div>

                <div className="chat-icon">
                    <img src={chat} alt="chat" onClick={() => {
                        this.setState({
                            chatForm: !this.state.chatForm
                        })
                    }} />

                    <div className={this.state.chatForm ? 'chat-form-container opened' : 'chat-form-container'}>
                    <span className="close-icon" onClick={() => {
                            this.setState({
                                chatForm: false
                            })
                        }}>x</span>
                        <h2>{'Kontakta oss'.translate(this.props.lang)}</h2>
                                {
                                        !this.state.loading1 ? 
                                        <ChatForm onSubmit={this.sendMessage1} lang={this.props.lang} />
                                        :null
                                    }
                                    {
                                        this.state.loading1 ? 
                                        <Player
                                            ref={this.player} 
                                            autoplay={true}
                                            keepLastFrame={true}
                                            //loop={true}
                                            controls={true}
                                            src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
                                            style={{ height: '100px', width: '100px' }}
                                        ></Player>
                                        :
                                        null
                                    }
                        <div className="chat-info">
                            <h6>
                                {'Du kan aven kontakta oss via telefon:'.translate(this.props.lang)}
                                <a href="tel:0107500655">{this.props.lang == 'en' ? '+46 (0)10-750 06 55' : '010-750 06 55'}</a>
                            </h6>
                        </div>
                    </div>
                </div>

                {/* <Isvg src={arrowDown} alt="cta" className={this.state.yScroll2 > 355 ? 'cta-arrow-2 cta-arrow-2-show': 'cta-arrow-2'} onClick={() => {
                    window.scrollTo(0, 0);
                }} /> */}

                <div className="home partners-home" style={{ backgroundImage: `url(${API_ENDPOINT + (this.state.forPartners && this.state.forPartners[0] && this.state.forPartners[0].backgroundImage)})` }}>
                    <Container>
                        <Col lg="8">
                            <div className="home-content">
                                <h1><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.forPartners && this.state.forPartners[0], 'title', this.props.lang)}}></div></h1>
                                <h6><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.forPartners && this.state.forPartners[0], 'shortDescription', this.props.lang)}}></div></h6>
                                <a href="#contact" className="button-clasic"><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.forPartners && this.state.forPartners[0], 'buttonText', this.props.lang)}}></div></a>
                            </div>
                        </Col>
                    </Container>
                    <a href="#partners" className="cta-arrow">
                        <Isvg src={arrowDown} />
                    </a>
                </div>

                <div id="partners" className="partners">
                    <Container>
                        <Row className="partners-row">
                            <Col lg="2">
                                <div className="partners-introduction">
                                    <h2>{'Några av våra partners'.translate(this.props.lang)}</h2>
                                </div>
                            </Col>
                            {
                                this.state.forPartners && this.state.forPartners[1] && this.state.forPartners[1].gallery1 && this.state.forPartners[1].gallery1.slice(0, 4).map((item, idx) => {
                                    return (
                                        <Col lg="2">
                                            <img src={API_ENDPOINT + item} />
                                        </Col> 
                                    )
                                })
                            }
                        </Row>
                        <Row className="partners-row">
                            <Col lg="2">
                                <div className="partners-introduction">
                                    <h2>{'Några av våra samarbeten'.translate(this.props.lang)}</h2>
                                </div>
                            </Col>
                            {
                                this.state.forPartners && this.state.forPartners[1] && this.state.forPartners[1].gallery1 && this.state.forPartners[1].gallery1.slice(4, 7).map((item, idx) => {
                                    return (
                                        <Col lg="2">
                                            <img src={API_ENDPOINT + item} />
                                        </Col> 
                                    )
                                })
                            }
                        </Row>
                    </Container>
                </div>

                <div id="contact" className="partner-contact-form">
                    <Container>
                        <Row>
                            <Col lg="7" className="h-700">
                                <h2>{'Kontakta oss'.translate(this.props.lang)}</h2>
                                    {
                                        !this.state.loading2 ? 
                                        <ContactForm onSubmit={this.sendMessage2} lang={this.props.lang} />
                                        :null
                                    }
                                    {
                                        this.state.loading2 ? 
                                        <Player
                                            ref={this.player} 
                                            autoplay={true}
                                            loop={true}
                                            controls={true}
                                            src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
                                            style={{ height: '300px', width: '300px' }}
                                        ></Player>
                                        :
                                        null
                                    }
                            </Col>
                        </Row>
                    </Container>
                </div>

            </div>
        );
    }
}

export default Page(OurSolutions);

