import React, { Component } from "react";
import Link from "../components/link";
import { connect } from "react-redux";
import { API_ENDPOINT } from "../constants";
import InfoModal from "../../src/components/InfoModal";
import CustomFooterLink from "../components/customFooterLink";
import Checkbox from '../components/forms/fields/check';
import footerBottom from "../assets/images/footer_image.png"
import Fb from '../assets/images/facebook.png'
import Ints from '../assets/images/instagram.png'
import Ln from '../assets/images/ln.png'

function parseJSON(response) {
  return response.json().then((json) => {
    return {
      result: json,
      status: response.status,
    };
  });
}

import Isvg from "react-inlinesvg";
import CuroflowLogoFooter from "../assets/svg/Curoflow_telemedicine_platform_logo_white.svg";

import {
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Label,
} from "reactstrap";

import mark from "../assets/images/mark.png";
import Brev from "../assets/images/brev@2x.png";

export class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      privacyPolicyCheckbox: false,
      errorPrivacy: false
    };
  }

  componentDidMount() {
    fetch(API_ENDPOINT + "/data/pages/all-footer", {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    })
      .then(parseJSON)
      .then(({ result, status }) => {
        this.setState({
          footerInfo: result,
        });
      });
  }
  componentDidUpdate(prevProps) {
    if (prevProps[0].location.pathname != this.props[0].location.pathname) {
      window.scrollTo(0, 0);
      
    }
  }

  sendNewLetter = (data) => {
    // if(!this.state.errorPrivacy) {
    //   this.setState({
    //     errorPrivacy: true
    //   })
    //   return
    // }
    fetch("https://site-api.curoflow.se/data/newsletter/new", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ email: data }),
    })
      .then(parseJSON)
      .then(({ result, status }) => {
        if (status != 200) {
          this.setState({
            error: true,
            newsInfo: false,
            // privacyPolicyCheckbox: !this.state.privacyPolicyCheckbox,
            errorPrivacy: false,
          });
        } else {
          this.setState({
            error: false,
            newsInfo: true,
            email: "",
            privacyPolicyCheckbox: !this.state.privacyPolicyCheckbox,
            errorPrivacy: false,
          });
        }
      });
  };
  checkPrivacyPolisy = () => {
    if (this.state.privacyPolicyCheckbox) {
        this.sendNewLetter(this.state.email)
    } else {
        this.setState({
            error: false,
            errorPrivacy: 'You must accept our Privacy Policy in order to continue.'.translate(this.props.lang)

        })
    }
}

  render() {
    return (
      <div>
        <footer className="footer">
          <div className="footer-content1">
            <div className="footer-left">
              <Link to="/">
                <Isvg src={CuroflowLogoFooter} className="logo-footer" />
              </Link>
              <p className="email">{"Email".translate(this.props.lang)}: <span>kontakt@curoflow.se </span></p>
              <p className="phone-curoflow">{"Telephone".translate(this.props.lang)}: <span>{"010 - 750 06 55".translate(this.props.lang) }</span></p>
            </div>
            <div className="footer-right">
              <div className="subscribe-container">
                {/* <h6>{"The latest".translate(this.props.lang)}</h6> */}
                <p>
                  {"Subscribe to our newsletter".translate(this.props.lang)}
                </p>
                <div>
                  <input
                    className=""
                    placeholder={"Your email".translate(this.props.lang)}
                    value={this.state.email}
                    onChange={(e) => {
                      if (e.target.value) {
                        this.setState({
                          email: e.target.value,
                        });
                      }else {
                        this.setState({
                          email: "",
                        });
                      }
                      this.setState({
                        error: false,
                        errorPrivacy: false
                      })

                      
                    }}
                  />
                  <div className="subscribe">  

                 
                  <button
                    className=""
                    onClick={() => this.checkPrivacyPolisy()}
                  >
                    {"Subscribe".translate(this.props.lang)}
                  </button>
                  <div className="sub-text">
                  

                 
                    <Checkbox
                      value={this.state.privacyPolicyCheckbox}
                      onChange={(value) => {
                        this.setState({
                          privacyPolicyCheckbox:value,
                          errorPrivacy: false,
                        });
                      }}
                      label={"I accept Curoflow’s".translate(this.props.lang)}
                    />
                     
                    <Link
                      lang={this.props.lang}
                      to="/integritetspolicy"
                    >
                      {"privacy policy".translate(this.props.lang)}
                    </Link>
                  </div>
                  </div>
                  {this.state.error ? (
                    <Label>
                      {"Ange en giltig e-postadress".translate(this.props.lang)}
                    </Label>
                  ) : null}
                  {this.state.errorPrivacy ? (
                    <Label>
                      {this.state.errorPrivacy.translate(this.props.lang)}
                    </Label>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="footer-content2">
            <CustomFooterLink
              {...this.props}
              pathName={"var-plattform"}
              apiName={"all-solution"}
              label={"Our platform".translate(this.props.lang)}
              lang={this.props.lang}
            />

            <CustomFooterLink
              {...this.props}
              pathName={"vi-hjalper"}
              apiName={"all-weassist"}
              label={"We assist".translate(this.props.lang)}
              lang={this.props.lang}
            />

<div className="content content-non-mob">
              <CustomFooterLink
                {...this.props}
                pathName={"resources"}
                apiName={"resources"}
                label={"Insights".translate(this.props.lang)}
                lang={this.props.lang}
              />
            {/* <div className="content">
         



              
            </div> */}
            <h6 className="social-media">{"Social media".translate(this.props.lang)}</h6>
              <ul className="footer-list non-mob">
              <li>
                  <a aria-label="Facebook" href="https://www.facebook.com/Curoflow?mibextid=ZbWKwL" className="social-link" target="_blank"><img src={Fb} alt="Curoflow Facebook" /> </a>
                  <a aria-label="Instagram" href="https://www.instagram.com/curoflow/" className="social-link" target="_blank"><img src={Ints} alt="Curoflow Instagram"/> </a>
                  <a aria-label="Linkedin" href="https://www.linkedin.com/company/18290458/" className="social-link" target="_blank"><img src={Ln} alt="Curoflow Linkedin" /> </a>
              </li>
              </ul>

              
            </div>
            <div className="content content-last">
            <CustomFooterLink
                {...this.props}
                pathName={"foretaget"}
                apiName={"all-aboutus"}
                label={"Company".translate(this.props.lang)}
                lang={this.props.lang}
              />
                     <h6 className="social-media">{"Social media".translate(this.props.lang)}</h6>
              <ul className="footer-list footer-list-mobile">
              <li>
                  <a aria-label="Facebook" href="https://www.facebook.com/Curoflow?mibextid=ZbWKwL" className="social-link" target="_blank"><img src={Fb} alt="Curoflow Facebook" /> </a>
                  <a aria-label="Instagram" href="https://www.instagram.com/curoflow/" className="social-link" target="_blank"><img src={Ints} alt="Curoflow Instagram"/> </a>
                  <a aria-label="Linkedin" href="https://www.linkedin.com/company/18290458/" className="social-link" target="_blank"><img src={Ln} alt="Curoflow Linkedin" /> </a>
              </li>
              </ul>
            </div>
          </div>
          <div className="footer-content3">
            <div className="footer-bottom1">
            <img src={footerBottom} alt='Copy right' />
              <p> 
                {"Curoflow telemedicine platform is a registered medical device under Regulation (EU) 2017/745 of the European Parliament and of the Council on medical devices (the MDR). All data is encrypted, stored and processed in accordance with the Swedish Patient Data Act (Sw. Patientdatalagen) and the EU General Data Protection Regulation (the GDPR)".translate(
                  this.props.lang
                )}.
              </p>
            </div>
            <div className="footer-copyright">
              <p>
                {"Copyright © 2024 Curoflow Technology AB".translate(
                  this.props.lang
                )}
              </p>
            </div>
          </div>
        </footer>
        {
              this.state.newsInfo ?
                  <InfoModal
                      isOpen={this.state.newsInfo}
                      toggle={() => this.setState({ newsInfo: !this.state.newsInfo })}
                      image={Brev}
                      header={'Info'.translate(this.props.lang)}
                      info={'Tack för att du anmält dig till vårt nyhetsbrev'.translate(this.props.lang)}
                      buttons={[
                          <button className='button-clasic' style={{padding:'0 35px'}} onClick={() => this.setState({ newsInfo: false, email: '' })}>{'Stäng'.translate(this.props.lang)}</button>,
                      ]}

                  />
                  :
                  null
          }
      </div>
    
    );
  }
}

export default Footer;
