import React, { Component } from 'react';

import Page from '../containers/page';

import 'react-circular-progressbar/dist/styles.css';
import { API_ENDPOINT } from "../constants";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import CuroflowIcon from "../assets/images/Curoflow_Digital_Telemedicine_Platform_Icon.png"
import SupportForm from '../components/forms/supportForm';
import Img from '../components/Img';



import ContactForm from '../components/forms/contactForm';
import AOS from 'aos';
import 'aos/dist/aos.css'

var striptags = require('striptags');

function parseJSON(response) {
    return response.json().then((json) => {
        return {
            result: json,
            status: response.status,
        };
    });
}

class WeAssist extends Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.player = React.createRef();

        this.state = {
            promotedProjects: [],
            poolActive: false,
            chatForm: false,
            yScroll2: 0,
            ourSolutions: [],
            solutionsStatus: false,
            ...props.initialData
        };
    }

    listenToScroll = () => {
        const yScroll2 =
            document.body.scrollTop || document.documentElement.scrollTop


        this.setState({
            yScroll2: yScroll2,
        })
    }

    sendMessage1 = (data) => {
        if (data.checked) {
            data.lang = this.props.lang
            this.setState({
                loading1: true
            }, () => {

                fetch(API_ENDPOINT + '/data/contact/new', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ data })
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                    }
                })
            })
        }
    }




    sendMessage2 = (data) => {
        this.props.setRecaptchaCallback(() => {
            if (data && data.checked && this.props.allowSubmit) {
                if (data.email && (data.email.toLowerCase().indexOf('@gmail.com') !== -1 || data.email.toLowerCase().indexOf('@live.com') !== -1 || data.email.toLowerCase().indexOf('@mailinator.com') !== -1 || data.email.toLowerCase().indexOf('@jollyfree.com') !== -1 || data.email.toLowerCase().indexOf('@gotgel.org') !== -1 || data.email.toLowerCase().indexOf('@yahoo.com') !== -1 || data.email.toLowerCase().indexOf('@hotmail.com') !== -1 || data.email.toLowerCase().indexOf('@business.com') !== -1 || data.email.toLowerCase().indexOf('@admin.com') !== -1 || data.email.toLowerCase().indexOf('@info.com') !== -1)) {
                    return this.setState({ contactFormError: 'Please fill in your work email' })
                } else this.setState({ contactFormError: null })


                // if (data.message?.length < 25) return this.setState({ contactFormMessageError: 'Please use at least 25 letters' });
                //     else this.setState({ contactFormMessageError: null })

                data.lang = this.props.lang
                data.url = typeof window !== 'undefined' ? window.location?.pathname : 'No Data';
                data.bookADemo = false;
                data.page = this.props?.[0]?.match?.params?.alias?.replace(/-/g, ' ') || 'No Data';
                data.token = this.props.recaptchaToken
                data.isMobile = this.props.isMobile
                data.osMobileNameUA = this.props.osMobileNameUA
                data.browserName = this.props.browserName,
                    data.browserNameUA = this.props.browserNameUA
                this.setState({
                    loading2: true
                }, () => {

                    setTimeout(() => {
                        this.player?.current?.pause()
                    }, 1120)

                    fetch(API_ENDPOINT + '/data/contact/new', {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({ data })
                    }).then(parseJSON).then(({ result, status }) => {
                        if (status === 429) {
                            this.setState({
                                solutionsStatus: true,
                                loading2: false
                            })
                        }
                        if (result.error) {
                            this.setState({ error: result.error })
                        } else {
                            this.props.bookDemo(false)
                            const path = 'thank-you';
                            this.props[0].history.push(`/${path}`);

                        }
                    })
                })
            }
        })
        this.props.updateToken();

    }





    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {

                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        // if (this.props[0].match.params.alias) {
        //     fetch(API_ENDPOINT + '/data/pages/all-weassist', {
        //         method: 'GET',
        //         headers: {
        //             'content-type': 'application/json'
        //         },
        //     }).then(parseJSON).then(({ result, status }) => {

        //         if (result.error) {
        //             this.setState({ error: result.error })
        //         } else {
        //             this.setState({
        //                 pageData: { ...result.filter(page => page.alias && page.alias[this.props.lang] && page.alias[this.props.lang] === ('/' + this.props[0].match.params.alias))[0] }
        //             })
        //         }
        //     })
        // }
    }

    componentDidMount() {
        this.get();

        if (this.props[0].location && !this.props[0].location.hash) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        } else if (this.props[0].location && this.props[0].location.hash) {
            document.getElementById(this.props[0].location.hash.replace('#', '')).scrollIntoView({ behavior: 'smooth', block: 'start' });
        }

        this.updateWindowDimensions();
        window.addEventListener('scroll', this.listenToScroll)

        AOS.init({
            duration: 1000
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.get()
        }
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render() {
        console.log('TEST: ', this.state.pageData);
        return (
            <div>


                <div className={this.props.openModalBookDemo ? 'contact-popup open' : 'contact-popup'}>
                    <span className="close-icon" onClick={() => {
                        this.props.bookDemo(false)
                    }}>x</span>
                    {
                        !this.state.loading3 && !this.state.supportStatus ?
                            <SupportForm onSubmit={this.sendMessage2} lang={this.props.lang} />
                            : null
                    }
                    {
                        this.state.loading3 ?
                            <Player
                                ref={this.player}
                                autoplay={true}
                                onEvent={(event) => {
                                    if (event == 'complete') {
                                        this.setState({
                                            supportForm: false,
                                            loading3: false
                                        })
                                    }
                                }}
                                //keepLastFrame={true}
                                //loop={false}
                                controls={true}
                                src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
                                style={{ height: '300px', width: '300px' }}
                            ></Player>
                            :
                            null
                    }
                    {this.state.supportStatus && <h2 className='status-popup support-status'>{'Please, try again later!'.translate(this.props.lang)}</h2>}
                </div>
                {
                    this.state.pageData ?
                        <div>

                            {
                                this.state.pageData.section1 ?
                                    <section className={`sectionTextBigimg sectionTextBigImgNoBg`}>
                                        <div className="title">
                                            {/* <h1 dangerouslySetInnerHTML={{__html: Object.translate(this.state.pageData && this.state.pageData.section[0], 'title', this.props.lang)}}></h1> */}
                                            <h1>{this.state.pageData && this.state.pageData.section[0] && this.state.pageData.section[0].title[this.props.lang]}</h1>
                                            <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData?.section[0] && this.state.pageData.section[0], 'shortDescription', this.props.lang) }}></div>
                                        </div>
                                        <img src={CuroflowIcon} className={`${(this.state.pageData && this.state.pageData.backgroundImagePositionRight) ? 'rightSideWeAssist' : " "} ${(this.state.pageData && this.state.pageData.backgroundImagePositionLeft) ? 'leftSideWeAssist' : " "}`} alt="Curoflow Digital Telemedicine Platform Icon" />
                                    </section>
                                    :
                                    null
                            }
                            {
                                this.state.pageData.section2 ?
                                    <section className={`curoflow-section curoflow-margin-bottom c-change ${(this.state.pageData && this.state.pageData.section[1] && this.state.pageData.section[1].item[0] && this.state.pageData.section[1].item[0].backgroundDark) ? 'curoflow-bg-dark1' : " "} ${(this.state.pageData && this.state.pageData.section[1] && this.state.pageData.section[1].item[0] && this.state.pageData.section[1].item[0].backgroundLightGrey) ? 'curoflow-bg-grey1' : " "}`}>
                                        <div className={`container-curoflow  ${(this.state.pageData && this.state.pageData.section[1] && this.state.pageData.section[1].item[1] && this.state.pageData.section[1].item[1].shortDescription && this.state.pageData.section[1].item[1].shortDescription.se) ? 'column-reverse' : "column-reverse"} `}>
                                            <div className="col-curoflow-lg-6 ">
                                                <h2>{this.state.pageData && this.state.pageData.section[1] && this.state.pageData.section[1].item[0]?.title[this.props.lang]} </h2>
                                                <div className='heading-weA' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[1]?.item[0] && this.state.pageData.section[1].item[0], 'shortDescription', this.props.lang) }}></div>
                                                <p className='para-weassist' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[1] && this.state.pageData.section[1].item[1], 'shortDescription', this.props.lang) }}></p>
                                                {this.state.pageData?.section?.[1]?.item?.[0]?.clientText?.[this.props.lang]?.trim?.() ?
                                                    <div className='client-about'>
                                                        {this.state.pageData?.section[1]?.item[0]?.clinicLogo ?
                                                            <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[1] && this.state.pageData.section[1].item && this.state.pageData.section[1].item[0] && this.state.pageData.section[1].item[0].clinicLogo)} className="logo-clinic" alt={(this.state.pageData && this.state.pageData.section[1] && this.state.pageData.section[1].item && this.state.pageData.section[1].item[0] && this.state.pageData.section[1].item[0].clinicLogo?.replace('/uploads/', '').replace(/_/g, ' ').slice(0, -4))} /> : null}

                                                        <h4 className='client-text'>{this.state.pageData && this.state.pageData.section[1] && this.state.pageData.section[1].item[0]?.clientText?.[this.props.lang]} </h4>
                                                        <p className='client-name'>{this.state.pageData && this.state.pageData.section[1] && this.state.pageData.section[1].item[0]?.clientName?.[this.props.lang]} </p>
                                                        <p className='client-position'>{this.state.pageData && this.state.pageData.section[1] && this.state.pageData.section[1].item[0]?.clientPosition?.[this.props.lang]} </p>
                                                    </div> : null}
                                                {/* {this.state.pageData?.section[1]?.item[1]?.clientText ? 
                                        <div className='client-about-right-mob'>
                                            {this.state.pageData?.section[1]?.item[1]?.clinicLogo ?  
                                            <img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[1] && this.state.pageData.section[1].item && this.state.pageData.section[1].item[1] && this.state.pageData.section[1].item[1].clinicLogo)} className="logo-clinic" alt={(this.state.pageData && this.state.pageData.section[1] && this.state.pageData.section[1].item && this.state.pageData.section[1].item[1] && this.state.pageData.section[1].item[1].clinicLogo?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))}/> : null}
                                           
                                            <h4 className='client-text'>{this.state.pageData && this.state.pageData.section[1]  && this.state.pageData.section[1].item[1]?.clientText?.[this.props.lang]} </h4>
                                            <p className='client-name'>{this.state.pageData && this.state.pageData.section[1]  && this.state.pageData.section[1].item[1]?.clientName?.[this.props.lang]} </p>
                                            <p className='client-position'>{this.state.pageData && this.state.pageData.section[1]  && this.state.pageData.section[1].item[1]?.clientPosition?.[this.props.lang]} </p>
                                        </div> : null} */}

                                            </div>
                                            <div className="col-curoflow-lg-6">


                                                <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section && this.state.pageData.section[1] && this.state.pageData.section[1].item && this.state.pageData.section[1].item[1] && this.state.pageData.section[1].item[1].image[this.props.lang])} className={` ${this.state.pageData?.section[1]?.item[1]?.clinicLogo ? 'img-margin' : 'imgNoMrgn'}`} alt={this.state.pageData && this.state.pageData.section && this.state.pageData.section[1] && this.state.pageData.section[1].item && this.state.pageData.section[1].item[1] && this.state.pageData.section[1].item[1].image[this.props.lang]?.replace('/uploads/', '').replace(/_/g, ' ').slice(0, -4)} />


                                                <p className='para-weassistNoMob' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[1] && this.state.pageData.section[1].item[1], 'shortDescription', this.props.lang) }}></p>
                                                {/* {this.state.pageData?.section[1]?.item[1]?.clientText ? 
                                        <div className='client-about-right'>
                                            {this.state.pageData?.section[1]?.item[1]?.clinicLogo ?  
                                            <img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[1] && this.state.pageData.section[1].item && this.state.pageData.section[1].item[1] && this.state.pageData.section[1].item[1].clinicLogo)} className="logo-clinic" alt={(this.state.pageData && this.state.pageData.section[1] && this.state.pageData.section[1].item && this.state.pageData.section[1].item[1] && this.state.pageData.section[1].item[1].clinicLogo?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))}/> : null}
                                           
                                            <h4 className='client-text'>{this.state.pageData && this.state.pageData.section[1]  && this.state.pageData.section[1].item[1]?.clientText?.[this.props.lang]} </h4>
                                            <p className='client-name'>{this.state.pageData && this.state.pageData.section[1]  && this.state.pageData.section[1].item[1]?.clientName?.[this.props.lang]} </p>
                                            <p className='client-position'>{this.state.pageData && this.state.pageData.section[1]  && this.state.pageData.section[1].item[1]?.clientPosition?.[this.props.lang]} </p>
                                        </div> : null} */}


                                            </div>
                                        </div>
                                    </section>
                                    :
                                    null
                            }
                            {
                                this.state.pageData.section3 ?
                                    <section className={`${(this.state.pageData && this.state.pageData.section[2] && this.state.pageData.section[2].item[0] && this.state.pageData.section[2].item[0].backgroundDark) ? 'curoflow-bg-dark1 curoflow-section-box ' : "no-padding curoflow-section"}`}>
                                        <div className="container-curoflow">
                                            <div className="col-curoflow-lg-6 ">
                                                <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[2] && this.state.pageData.section[2].item[0] && this.state.pageData.section[2].item[0].image?.[this.props.lang])} className="border-img" alt={(this.state.pageData && this.state.pageData.section[2] && this.state.pageData.section[2].item[0] && this.state.pageData.section[2].item[0].image?.[this.props.lang]?.replace('/uploads/', '').replace(/_/g, ' ').slice(0, -4))} />
                                                {this.state.pageData?.section[2]?.item[0]?.clientText ? <div className='client-about'>
                                                    {this.state.pageData?.section[2]?.item[0]?.clinicLogo ? <Img src={API_ENDPOINT + this.state.pageData.section[2].item[0].clinicLogo} alt={(this.state.pageData && this.state.pageData.section[2] && this.state.pageData.section[2].item[0] && this.state.pageData.section[2].item[0].clinicLogo?.replace('/uploads/', '').replace(/_/g, ' ').slice(0, -4))} /> : null}

                                                    <h4 className='client-text'>{this.state.pageData && this.state.pageData.section[2] && this.state.pageData.section[2].item[0]?.clientText?.[this.props.lang]} </h4>
                                                    <p className='client-name'>{this.state.pageData && this.state.pageData.section[2] && this.state.pageData.section[2].item[0]?.clientName?.[this.props.lang]} </p>
                                                    <p className='client-position'>{this.state.pageData && this.state.pageData.section[2] && this.state.pageData.section[2].item[0]?.clientPosition?.[this.props.lang]} </p>
                                                </div> : null}
                                            </div>
                                            <div className="col-curoflow-lg-6">
                                                <h2>{this.state.pageData && this.state.pageData.section[2] && this.state.pageData.section[2].item[1]?.title[this.props.lang]} </h2>
                                                <p dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[2] && this.state.pageData.section[2].item[1], 'shortDescription', this.props.lang) }}></p>
                                            </div>
                                        </div>
                                    </section>
                                    :
                                    null
                            }
                            {
                                this.state.pageData.section4 ?
                                    <section className='curoflow-section curoflow-margin-top'>
                                        <div className="container-curoflow">
                                            <div className="col-curoflow-lg-6 ">
                                                <h2>{this.state.pageData && this.state.pageData.section[3] && this.state.pageData.section[3].item[0]?.title[this.props.lang]} </h2>
                                                <p dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[3] && this.state.pageData.section[3].item[0], 'shortDescription', this.props.lang) }}></p>
                                            </div>
                                            <div className="col-curoflow-lg-6">
                                                <p dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[3] && this.state.pageData.section[3].item[1], 'shortDescription', this.props.lang) }}></p>

                                                {this.state.pageData?.section[3]?.item[3]?.clientText ? <div className='client-about'>
                                                    {this.state.pageData?.section[3]?.item[2]?.clinicLogo ? <Img src={API_ENDPOINT + this.state.pageData.section[3].item[2].clinicLogo} alt={(this.state.pageData && this.state.pageData.section[3] && this.state.pageData.section[3].item[2] && this.state.pageData.section[3].item[2].clinicLogo?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} /> : null}

                                                    <h4 className='client-text'>{this.state.pageData && this.state.pageData.section[3] && this.state.pageData.section[3].item[3]?.clientText?.[this.props.lang]} </h4>
                                                    <p className='client-name'>{this.state.pageData && this.state.pageData.section[3] && this.state.pageData.section[3].item[4]?.clientName?.[this.props.lang]} </p>
                                                    <p className='client-position'>{this.state.pageData && this.state.pageData.section[3] && this.state.pageData.section[3].item[5]?.clientPosition?.[this.props.lang]} </p>
                                                </div> : null}
                                            </div>
                                        </div>
                                    </section>
                                    :
                                    null
                            }
                            {
                                this.state.pageData.section5 ?
                                    <section className='curoflow-section curoflow-margin-top'>
                                        <div className="container-curoflow">
                                            <div className="col-curoflow-lg-6 ">

                                                <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[1] && this.state.pageData.section[4].item[1].image)} className="border-img" alt={(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[1] && this.state.pageData.section[4].item[1].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                            </div>
                                            <div className="col-curoflow-lg-6">
                                                <h2>{this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[1]?.title[this.props.lang]} </h2>
                                                <p dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[4]?.item[1] && this.state.pageData.section[4].item[1], 'shortDescription', this.props.lang) }}></p>
                                            </div>
                                        </div>
                                    </section>
                                    :
                                    null
                            }
                            {
                                this.state.pageData.section6 ?
                                    <div>

                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.pageData.section7 ?
                                    <section className="no-padding curoflow-section">
                                        <div className="container-curoflow">
                                            <div className="col-curoflow-lg-6 ">
                                                <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[0] && this.state.pageData.section[6].item[0].image?.[this.props.lang])} className="border-img" alt={(this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[0] && this.state.pageData.section[6].item[0].image?.[this.props.lang].replace('/uploads/', '').replace(/_/g, ' ').slice(0, -4))} />
                                            </div>
                                            <div className="col-curoflow-lg-6">
                                                <h2>{this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[1]?.title[this.props.lang]} </h2>
                                                <p dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[1], 'shortDescription', this.props.lang) }}></p>
                                            </div>
                                        </div>
                                    </section>
                                    :
                                    null
                            }
                            {
                                (this.state.pageData.section8 && !this.state.pageData?.section[7]?.item[1]?.sweeden) || (this.state.pageData.section8 && this.state.pageData?.section[7]?.item[1]?.sweeden && this.props.lang === 'se') ?
                                    <section className="no-padding curoflow-section curoflow-top ">
                                        <div className="container-curoflow column-reverse">
                                            <div className="col-curoflow-lg-6">
                                                <h2>{this.state.pageData && this.state.pageData.section[7] && this.state.pageData.section[7].item[1]?.title[this.props.lang]} </h2>
                                                <p dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[7] && this.state.pageData.section[7].item[1], 'shortDescription', this.props.lang) }}></p>
                                            </div>
                                            <div className="col-curoflow-lg-6 ">
                                                <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[7] && this.state.pageData.section[7].item[0] && this.state.pageData.section[7].item[0].image)} className="border-img" alt={(this.state.pageData && this.state.pageData.section[7] && this.state.pageData.section[7].item[0] && this.state.pageData.section[7].item[0].image?.replace('/uploads/', '').replace(/_/g, ' ').slice(0, -4))} />
                                            </div>

                                        </div>
                                    </section>
                                    :
                                    null
                            }
                            <ContactForm title={this.state.pageData && this.state.pageData.section[5] && this.state.pageData.section[5].item[0]?.title[this.props.lang]} text={this.state.pageData && this.state.pageData.section[5] && this.state.pageData.section[5].item[0] ? Object.translate(this.state.pageData && this.state.pageData.section[5] && this.state.pageData.section[5].item[0], 'shortDescription', this.props.lang) : null} contactFormError={this.state.contactFormError} class={'grey'} onSubmit={this.sendMessage2} lang={this.props.lang} contactFormMessageError={this.state.contactFormMessageError} />
                        </div>
                        :
                        null
                }

            </div>
        );
    }
}

export default Page(WeAssist);

