import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Isvg from "react-inlinesvg";
import Page from "../containers/page";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { API_ENDPOINT } from "../constants";
import { Player, Controls } from "@lottiefiles/react-lottie-player";

import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  UncontrolledDropdown,
  Input,
} from "reactstrap";

import ContactForm from "../components/forms/contactForm";
import arrowDown from "../assets/svg/arrow-down.svg";
import p1 from "../assets/images/p1.png";
import p2 from "../assets/images/p2.png";
import p3 from "../assets/images/p3.png";
import p4 from "../assets/images/p4.png";
import p5 from "../assets/images/p5.png";
import p6 from "../assets/images/p6.png";
import p7 from "../assets/images/p7.png";
import chat from "../assets/images/chat.png";
import ChatForm from "../components/forms/chatForm7";

var striptags = require("striptags");

function parseJSON(response) {
  return response.json().then((json) => {
    return {
      result: json,
      status: response.status,
    };
  });
}

class ServiceDescriptionPage extends Component {
  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.player = React.createRef();

    this.state = {
      promotedProjects: [],
      poolActive: false,
      ...props.initialData,
      chatForm: false,
      yScroll2: 0,
      serviceDescriptionPage: [],
      ...props.initialData,
    };
  }

  listenToScroll = () => {
    const yScroll2 =
      document.body.scrollTop || document.documentElement.scrollTop;

    this.setState({
      yScroll2: yScroll2,
    });
  };

  sendMessage1 = (data) => {
    if (data.checked) {
      data.lang = this.props.lang;
      this.setState(
        {
          loading1: true,
        },
        () => {
          fetch(API_ENDPOINT + "/data/contact/new", {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({ data }),
          })
            .then(parseJSON)
            .then(({ result, status }) => {
              if (result.error) {
                this.setState({ error: result.error });
              } else {
              }
            });
        }
      );
    }
  };

  get = () => {
    this.props.updateMeta(
      this.props.generateSeoTags(this.state, this.props.lang)
    );

    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            );
          }
        );
      });
    }

    for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
      this.props.loadDataWithQuery[i](
        window.fetch,
        this.props.lang,
        this.props[0].match
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            );
          }
        );
      });
    }
  };

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }

    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match
      ).then((data) => {
        this.setState({
          ...data,
        });
      });
    }

    this.get();

    this.updateWindowDimensions();
    window.addEventListener("scroll", this.listenToScroll);

    const pathname = window.location.pathname;
    if (pathname.startsWith('/en')) {
      this.props.changeLang?.('en')
    } else {
      this.props.changeLang?.('se')
    }
    
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentDidUpdate(prevProps) {
    if (prevProps[0].location.pathname != this.props[0].location.pathname) {
      // this.get()
    }
  }

  render() {


    return (
      <div>
 

        <div className="cookies">
          <Container>
            <Row>
              <Col lg="12">
                <div
                  dangerouslySetInnerHTML={{
                    __html: Object.translate(
                      this.state.serviceDescriptionPage && this.state.serviceDescriptionPage[0],
                      "shortDescription",
                      this.props.lang
                    ),
                  }}
                ></div>
            
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Page(ServiceDescriptionPage);
